.screen_container {
  height: 100vh;
  overflow: hidden;
  z-index: 9999999999999;
}

.screen_wrapper {
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policies_screen {
  height: 100%;
  width: 100%;
  border-radius: 35px;
  background-color: #ffffff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.policies_popup_container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.policies_screen_row {
  height: 100%;
  background: #ffffff;
  border-radius: 30px;
}

.tokenBtnAmra {
  z-index: 9999 !important;
}

.policies_popup_container {
  padding-top: 2rem;
}

.nafiaImg {
  padding: 2rem 0 0 0;
  /* margin-bottom: 0 !important;
  height: 100%; */
}

.nafiaImg2 {
  text-align-last: center;
  margin-bottom: 0 !important;
}

.nafia {
  width: 100%;
  /* height: 100%; */
}

.langText h2 {
  font-weight: 400;
  background: linear-gradient(
    to right,
    #f48908 3.01%,
    #f51929 20.37%,
    #9713b9 51.36%,
    #b620a9 69.35%,
    #7602bd 97.54%
  );
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.tobegib {
  font-size: 19px;
  margin-bottom: 1.5rem !important;
}

.langText2 {
  margin-bottom: 1rem !important;
} 

.nafia2 {
  border-radius: 50px;
  width: 100%;
  height: 100%;
  border: none;
  margin-bottom: 0 !important;
}

.popUp_ {
  background: rgba(247, 232, 252, 1);
  border-radius: 30px;
  width: fit-content;
  margin-bottom: 0.8rem;
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.permision_popUp {
  padding: 2rem 1.5rem;
}

.privacy_popUp,
.agree_popUp {
  padding: 1.5rem;
}

.popUp_ h6 {
  margin-bottom: 1rem;
}

.popUp_ p {
  font-size: 14px;
}

.privacy_popUp {
  display: flex;
  flex-direction: column;
}

.privacy_popUp p {
  margin-bottom: 0;
}

.privacy_popUp button {
  border: none;
  outline: none;
  align-self: flex-end;
  padding: 0.2rem 1rem;
  transition: all 0.2s ease-in;
  background: transparent;
}

.privacy_popUp button:hover {
  background-color: #ffffff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 20px;
  font-weight: 500;
}

.agree_popUp {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-self: flex-end;
}

.agree_popUp button {
  border: 1px solid rgba(48, 48, 48, 1);
  border-radius: 15px;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  font-size: 15px;
  background: transparent;
  padding: 0.3rem 1rem;
  transition: all 0.2s ease-in;
}

.agree_popUp button:hover {
  transform: scale(1.1);
  font-weight: 500;
  color: #ffffff;
}

.agree_popUp .acceptBtn:hover {
  background-color: rgba(175, 211, 113, 1);
}

.agree_popUp .acceptBtn.acceptSelected {
  background-color: rgba(175, 211, 113, 1);
}

.agree_popUp .rejectBtn:hover {
  background-color: rgba(255, 114, 94, 1);
}

.agree_popUp .rejectBtn.rejectSelected {
  background-color: rgba(255, 114, 94, 1);
}

.domain_popUp {
  width: 100%;
}

.input_confirm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.domain_input input {
  border: none;
  border-bottom: 2px solid #363636;
  background-color: transparent;
  padding: 0.5rem 1rem 0.1rem 1rem;
  width: 100%;
  font-size: 18px;
  outline: none;
}

.domain_popUp button {
  align-self: flex-end;
  width: fit-content !important;
}

/** loading **/

/* Absolute Center Spinner */

.policies_screen {
  position: relative;
}

.loading {
  display: block !important;
}

.loader {
  display: none;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}

.loader_mic {
  display: none;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.loader_mic_load {
  display: none;
  position: absolute;
  bottom: 5rem;
  left: 1.5rem;
}

.loader_speaker {
  display: none;
  position: absolute;
  bottom: 4.5rem;
  left: 1.45rem;
}

.mic_image {
  width: 60px;
}

.mic_load_image {
  width: 45px;
}

.speaker_load_image {
  border-radius: 50%;
  width: 47px;
}

@media screen and (max-width: 768px) {
  .policies_popup_container,
  .nafiaImg {
    height: auto;
  }

  .policies_popup_container {
    margin: auto !important;
  }
}

.speaker_load_image2 {
  width: 60%;
  height: auto;
}

.center_data {
  text-align: center;
}

.talkinghead_realplayer {
  flex: 6 1;
  display: flex;
  height: 473px;
}

#avatar_realplayer {
  flex: 1;
  overflow: hidden;
}

.taking_nafia {
  margin-top: auto;
  margin-bottom: auto;
}

.taking_center {
  margin-left: auto;
  margin-right: auto;
}

.nafiaImg canvas {
  width: 100%;
  height: 77%;
}

.m-auto-left-right-bottom {
  margin-top: 105px;
  margin-bottom: -31px;
  margin-right: auto;
  margin-left: auto;
}

.display_show_faq {
  display: none !important;
}
.left_icon_show {
  text-align: left !important;
}

.loadeTechno {
  border-radius: 30px !important;
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 9999999999999999;
}

.float_right_nafia {
  float: right;
}

.back_color{

  background-color: #FEFCFE !important;
}
.text_center_float{
  text-align: center;
}

.policies_popup_container {
  display: flex;
  justify-content: center;
}

.tokenBtnAmra {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}