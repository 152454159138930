.amrascreen {
  width: 100vw;
  height: 100vh;
  background-color: #f7e8fc;
  padding: 2rem;
}

.amarClientLeftImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.amarClientLeftImg img {
  width: 60%;
}

.amarClientRightImg {
  width: 100%;
  margin: 3rem 0 !important;
}

.amarClientRightImg img {
  width: 60%;
}

.amarClientCenterImg {
  width: 100%;
}

.clientImg01 img {
  width: 100%;
}

.startAmraDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}

.amarClientCenterImg img {
  width: 100%;
}

.amrascreen_wrapper {
  background-color: #fcfcff;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amrascreen_wrapperFlex {
  flex-direction: column !important;
  justify-content: flex-end !important;
}

.amrascreen_wrapperHead {
  text-align: center;
  margin: 2rem;
}

.amarClientRight {
  margin-left: 0 !important;
}

.amarClientLeft {
  margin-right: 0 !important;
}

.bottomAmraText {
  text-align: center;
  margin: 3rem 0;
}

.amrascreen_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.amrascreen_wrapperHead h2 {
  font-weight: 400 !important;
}

.startAmraBtn {
  gap: 1rem ;
  padding: 0.6rem 2.1rem;
  background-color: #eaeaea;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  transition: 0.2s all ease-in;
  color: #575555;
  border: 1px solid rgba(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 15px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.startAmraBtn1 {
  padding: 0.5rem 1.5rem !important;
  font-size: 14px !important;
}

.hidetokenbtn {
  display: none !important;
}

.startAmraBtn:hover {
  background: linear-gradient(120.62deg, #ec268f 10.86%, #f58634 115.47%);
  color: #ffffff;
}

/* --------------------------------------------------- */

.loading-screen {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f0f0f0;
  border-radius: 18px;
}

.loading-bar {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-bar .loading-text {
  font-size: 70px;
  font-weight: 700;
  -webkit-text-stroke: 2px rgb(86, 86, 86);
  color: #ffffff;
}

.loading-bar::before {
  content: "";
}

.progress {
  height: 12px;
  border: 1px solid rgb(86, 86, 86);
  background: #3498db;
  transition: width 0.5s ease-in-out;
  /* Add a transition for smooth color fill */
  z-index: 99999999999999;
  border-radius: 20px;
}

.hiddenLoadingScreen {
  display: none !important;
}

.btnALngDiv {
  display: flex;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .amarClientLeft,
  .amarClientRight {
    padding: 0;
  }
}

@media screen and (max-width: 576px) {
  .amrascreen {
    padding: 2rem 0.7rem !important;
  }
}

@media screen and (max-width: 480px) {
  .startAmraBtn {
    padding: 0.6rem 1.5rem;
  }
}

.amarClientCenterImg video {
  width: 100%;
}

.amra_screen_color {
  background-color: #fffeff;
}

.back_tra {
  background: transparent;
  border: none;
}

.font_size_30 {
  font-size: 30px;
}

.amrascreen_wrapper {
  position: relative;
}

.tokenBtnAmra {
  position: absolute;
  top: 2rem;
  right: 3rem;
}

.modal-header2 {
  justify-content: space-between !important;
  color: #575555;
}

.mainlogoAmra {
  width: 220px;
  position: absolute;
  top: 1.5rem;
  left: 3rem;
}

.mainlogoAmra img {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .mainlogoAmra {
    width: 200px;
    position: absolute;
    top: 1.5rem;
    left: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .mainlogoAmra {
    width: 150px;
    position: absolute;
    top: 1rem;
    left: 1.5rem;
  }

  .tokenBtnAmra {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
}

.red_error {
  text-align-last: center;
  margin-top: 10px;
  color: red;
}
