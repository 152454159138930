.headsOfKU {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.3s all ease-in-out;
}

#colorChangingText {
  color: black;
  cursor: pointer;
}

.clickable-heading {
  cursor: pointer;
}

.knowUpdHeadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0.8rem 0 0.8rem;
}

.headsOfKU h6 {
  color: #343a40;
}

.activeheadsOfKU h6 {
  color: #9648d4 !important;
}

.activeheadsOfKU {
  border-bottom: 2px solid rgba(126, 50, 203, 1);
}

.headsOfKU:hover {
  border-bottom: 2px solid rgba(126, 50, 203, 1);
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  height: 75vh;
  /* Adjust as needed */
}

.crawler {
  margin-top: 10px;
}

.btnCrawler {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  border: 1px solid #e7e7e7;
  padding: 0.8rem 1rem;
  border-radius: 7px;
  color: #ffffff;
  background-color: #7e32cb;
}

.addFILE {
  justify-content: flex-end;
}

.crwalingDiv {
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.URLSercher input {
  height: fit-content;
}

.URLSercher button {
  height: 45px;
  padding: 0 1rem;
}

.URLSercher {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.button_crown {
  margin-top: 1px;
}

.button_crown p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.Filetxt {
  color: #410f74;
}

.KBarea input {
  background-color: #eeecec;
  outline: none;
  border: none;
}

.KBDropdown select {
  background-color: #eeecec;
}

.KBtxt,
.KBarea {
  margin-right: 10px;
}

.KB {
  justify-content: flex-end;
}

.num input {
  background-color: #eeecec;
}

.btnaddfile input {
  outline: none !important;
  border: none;
  margin-left: 5px;
  height: 35px;
  border-radius: 5px;
}

.lstSSection {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 22.5rem;
}

.fSection {
  margin-top: 20px;
}

.dltimgg {
  margin-right: 10px;
}

.headfile {
  color: red;
}

.lstSection {
  margin-top: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.input {
  width: 40px;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 36px;
  cursor: not-allowed;
  pointer-events: none;
}

.input:focus {
  border-bottom: 3px solid orange;
  outline: none;
}

.input:nth-child(1) {
  cursor: pointer;
  pointer-events: all;
}

.UPDationbtn1 {
  text-align: end;
}

.PRObtn {
  color: black;
  background-color: green;
  border: none;
  border-radius: 5%;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 0.5px;
}

.knldgUpdateContainer {
  margin-top: 0.5rem;
}

.urlText p {
  margin: 0;
  padding-left: 0.4rem;
  font-size: 14px;
}

.webInputDiv {
  background-color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  padding: 0 0.5rem;
}

.webInputDiv img {
  width: 1.8rem;
}

.urlText input {
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  color: #343a40;
}

.addFILEBtn {
  background-color: #7e32cb;
  color: #ffffff;
  height: 45px;
  border-radius: 6px;
  padding: 0 2rem;
  font-size: 18px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.crwalTableContainer {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
}

.crwalTable table thead tr th div {
  height: 55px;
  border-bottom: 1px solid #a1a1a1;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-top: 2px solid #6d7074;
  background-color: #ffffff;
}

.crwalTable table tbody tr td div {
  height: 55px;
  border-bottom: 1px solid #a1a1a1;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.crwalTable table thead tr th div p {
  margin-bottom: 0;
  color: #868e96;
  font-weight: 600;
}

.crwalTable table tbody tr td div p {
  margin-bottom: 0;
  color: #1e1e1f;
  font-size: 15px;
  font-weight: 500;
}

.crwalTable table tbody tr td div .statusSuccess {
  background-color: #63a813;
  color: #ffffff;
  font-weight: 500;
  padding: 0.2rem 0.7rem;
  border-radius: 30px;
  font-size: 14px;
}

.crwalTable table tbody tr td div .statusError {
  background-color: #d81515;
  color: #ffffff;
  font-weight: 500;
  padding: 0.2rem 0.7rem;
  border-radius: 30px;
  font-size: 14px;
}

.crwalTable table {
  width: 100%;
}

.firstCol {
  border-left: 2px solid #6d7074;
  border-top-left-radius: 20px;
}

.lastCol {
  border-right: 2px solid #6d7074;
  border-top-right-radius: 20px;
}

.urlFOundtxt {
  border: 2px solid #6d7074;
  border-top: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
  height: 60px;
}

.urlFOundtxt p {
  margin: 0;
  color: #868e96;
  font-weight: 600;
}

.crwalTableWrapper {
  padding: 10px;
}

.tdFIrstCol {
  border-left: 1px solid #a1a1a1;
}

.tdLAstCol {
  border-right: 1px solid #a1a1a1;
}

.tdLAstCol img {
  width: 1.5rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.RmvDataSource {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #343a40;
  cursor: pointer;
  padding: 10px;
}

.RmvDataSource p {
  margin: 0;
}

.dataSource {
  display: flex;
  justify-content: flex-end;
}

.TTLcharacter {
  margin: 1.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TTLcharacter p {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

.TTLcharacter button {
  border: none;
  background-color: #7e32cb;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}

.TTLcharacter button h5 {
  margin: 0;
  color: #ffffff;
}

.moving-color {
  width: 100%;
  height: 7px;
  background: linear-gradient(to right, #ffffff, #963fee, #ffffff);
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: moveColor 1.5s linear infinite;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

@keyframes moveColor {
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.addFileTableWrapper {
  padding: 12px;
}

.addFileTableContainer {
  background-color: #ffffff;
  border-radius: 20px;
}

.addFileTableHeadLeft {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addFileTableHeadLeft img {
  width: 3rem;
}

.addFileTableHeadLeft h4 {
  margin: 0;
  font-weight: 600;
  color: #7e32cb;
}

.addFileTableHeadRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.addFileTableHeadRight h5 {
  margin: 0;
}

.addFileTableHeadRight input {
  background-color: #eeecec;
  border: none;
  outline: none;
  border-radius: 6px;
  height: 40px;
  width: 230px;
  padding: 0 0.5rem;
}

.addFileTableHeadRight select {
  outline: none;
  border-radius: 6px;
  height: 40px;
  background-color: #eeecec;
}

.addFileTableHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.addFileTable {
  border-radius: 24px;
  border: 2px solid #6d7074;
  padding-bottom: 1.5rem;
  overflow: hidden;
}

.addFileTable table {
  width: 100%;
}

.addFileTable table thead tr th div {
  background-color: #707070;
  height: 55px;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.addFileTable table tbody tr td div {
  height: 55px;
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.addFileTable table tbody tr {
  box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
}
.addFileTable table thead tr th div p {
  margin: 0;
}

.addFileTable table tbody tr td div p {
  margin: 0;
  font-weight: 500;
}

.processeDone {
  color: #ffffff;
  background-color: #63a813;
  padding: 0.2rem 0.7rem;
  border-radius: 6px;
  font-size: 14px;
}

.filefirstCol {
  border-top-left-radius: 20px;
}

.filelastCol {
  border-top-right-radius: 20px;
}

.updatebyContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1rem;
}

.updateByphoneinput,
.updateByInput {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.updateByphoneinput h6,
.updateByInput h6 {
  white-space: nowrap;
  margin: 0;
}

.updateByInput input {
  outline: none;
  border: none;
  background-color: #eeecec;
  height: 40px;
  border-radius: 6px;
  max-width: 250px;
  padding: 0 0.5rem;
  font-size: 15px;
}

.updateByphoneinputContainr {
  background-color: #eeecec;
  height: 40px;
  border-radius: 6px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updateByphoneinputContainr input {
  max-width: 180px;
}

.updateByphoneinputContainr
  .react-international-phone-input-container
  .react-international-phone-input {
  border: none !important;
}

.updateByphoneinputContainr p {
  font-size: 14px;
  color: #cd1717;
  white-space: nowrap;
  margin: 0;
  padding-right: 0.5rem;
}

.getOTP {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}

.getOTP input {
  width: 40px;
  height: 40px;
  outline: none;
  border: none;
  background-color: #eeecec;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
}

.updateBtnsRight button {
  border: none;
  background-color: #7e32cb;
  padding: 0.5rem 2rem;
  border-radius: 6px;
}

.updateBtnsRight button h5 {
  margin: 0;
  color: #ffffff;
}

.updateBtnsContaienr {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 1rem;
  padding-bottom: 1.2rem;
}

.addFileTableContainer2 {
  height: 335px;
  overflow: auto;
  width: 100% !important;
}

.addFileTableContainer2::-webkit-scrollbar {
  width: 5px;
}

.crwalTable::-webkit-scrollbar {
  width: 0;
}

th {
  position: sticky;
  top: 7px;
}

.disabledbtnCrawler {
  opacity: 0.5;
  pointer-events: none;
}

.nodataInput {
  margin: 1.5rem 1rem;
  text-align: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optmprfrmcDiv {
  margin: 2rem 0;
}

.centered-divKB {
  position: relative;
}

.TechDisputes {
  position: absolute;
  top: 0px;
  left: -30px;
}

.KBDisputes {
  position: absolute;
  right: -35px;
  bottom: 30px;
}

.KBDisputes p,
.TechDisputes p {
  margin: 0;
  color: #7c7c7c;
  font-weight: 500;
  font-size: 15px;
}

.KBDisputes h5,
.TechDisputes h5 {
  color: #0a0a0a;
  font-weight: 600;
}

/* Generating report Nafia User */

.interactionCards {
  padding-left: 1.2rem;
  height: 100%;
  display: flex;
  align-items: center;
}

/* -------------------------------------------------------------------------------------------------------- */

.disableAddfilebtn {
  /* cursor: not-allowed !important; */
  pointer-events: none !important;
  opacity: 0.5;
}

.urlAndFileTabs {
  background-color: #ffffff;
  margin: 0 12px;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.urlAndFileTabs p {
  margin: 0;
  font-weight: 500;
  color: #343a40;
}

.urlAndFileTabs .urlAndFileTabsContainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.urlAndFileTabs .urlTab {
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;
}

.activeKBTab {
  border-bottom: 2px solid #7e32cb !important;
}

.activeKBTab p {
  color: #7e32cb !important;
}

/* ---------------------------------------------------------------------------------------------------------------------------- */

.loader_round {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #800080;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

::-webkit-scrollbar-horizontal {
  height: 6px !important;
}
