.debtordetailCardHead img {
  cursor: pointer;
}
.debtorFilterContainer input {
  outline: none;
}

.debtorImag img {
  width: 100%;
}

.infoDetails {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.infoDetails h6,
.infoDetails p {
  margin: 0;
  white-space: nowrap;
}

.infoDetails p {
  color: #7e7c7c;
  font-weight: 500;
}

.infoDetails h6 {
  background-color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 6px;
}

.infoContainer {
  display: flex;
  justify-content: space-around;
  gap: 0.5rem;
  height: 100%;
}

.phoneDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modalHeadPopUp2 {
  border-bottom: 1px solid #e2e2e2 !important;
}

@media screen and (max-width: 1200px) {
  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.5rem;
    height: 100%;
  }

  .phoneDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
  }

  .qualificationData {
    margin-top: 1.5rem !important;
  }
}

.debtorPerslInfoContainer h5 {
  margin: 1rem 0;
  padding-top: 0.5rem;
  border-top: 1px solid #7e7c7c;
}

.prsnlDetails {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.prsnlinfoDetail {
  gap: 0.5rem;
}

.adressDetailDiv {
  flex-direction: column;
  align-items: flex-start !important;
}

.adressDetailDiv h6 {
  padding: 1rem !important;
}

.prsnlinfoContainer .row {
  margin-bottom: 1.5rem !important;
}

.uploadButtonCity {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.csvSubmitbtn button {
  border: none !important;
  font-weight: 500;
  background-color: #8946c7;
  color: #ffffff;
  border-radius: 7px;
  padding: 0.4rem 2rem;
  width: 80% !important;
}

.csvSubmitbtn {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.customfileCsvinput {
  background-color: #7e7c7c;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.6rem;
  margin-right: 0.2rem;
  cursor: pointer;
}

#file-namelabel {
  font-weight: 500;
}

.smpleCsvinput {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal_body12 p {
  text-align: left !important;
  margin-bottom: 1.5rem !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
}

.promToPay {
  width: 100% !important;
}

.circleProgBarPopUp {
  flex-direction: column !important;
  height: 100%;
  margin: auto !important;
}

.circleProgBarPopUp .collecBAr,
.circleProgBarPopUp .collecBAr1 {
  margin: auto !important;
  margin-bottom: 1rem !important;
}

.popupdtailsCard p {
  font-size: 14px;
}

.popupdtailsCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popupdtailsCard h4 span {
  font-size: 12px;
}

.p-dropdown-panel {
  z-index: 999999999999999 !important;
}

.debtorProfileModalW {
  --bs-modal-width: auto !important;
}

.seeAllmodalBtn {
  background-color: #8946c7;
  color: #ffffff;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  font-size: 13px;
}
