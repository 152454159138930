.completeProfileBtn {
  border-color: white;
  border-radius: 6px;
  padding: 5px;
  color: rgba(31, 100, 255, 1);
  font-size: 15px;
}

.collecBar {
  background-color: grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem;
  margin-bottom: 1rem;
  height: 100%;
}

.profileTxt p {
  color: white;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

.button-container {
  display: flex;
  flex-direction: column;
}

.custom-button {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.custom-button:hover {
  background-color: #ffffff;
}

.column1 {
  padding: 15px;
}
.column2 {
  background-color: white;
  border-radius: 2.5%;
  padding: 15px;
}

.subscript {
  margin-bottom: 1.5rem;
}

.updbtn {
  border: 5px;
  border-radius: 2.5%;
}

.upgbtn {
  background-color: rgba(52, 58, 64, 1);
  color: #ffffff;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  box-shadow: 1px 4px 10px 0px #1f64ff40;
}

.canbtn {
  border: 1px solid rgba(143, 177, 255, 1);
  background-color: #fff;
  color: rgba(126, 50, 203, 1);
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.teamPack {
  margin-bottom: 0.2rem;
  font-weight: 500;
}

.planRenew {
  font-size: 14px;
  margin-top: 0.5rem;
}

.perMonth {
  margin-bottom: 16px;
  font-weight: 400;
}

.currentPlan,
.pymtMethod {
  margin-bottom: 3rem;
  cursor: pointer;
}

.crntPlanHead {
  margin: 0;
  font-weight: 500;
}

/****USer management***/
.column3 {
  margin-left: 25px;
}
.addImg {
  border: none;
}
.imgAdd p {
  margin-top: 12px;
  margin-left: 12px;
  color: rgba(31, 100, 255, 1);
  font-weight: 600;
}
.proTxt p {
  color: rgba(0, 0, 34, 0.5);
}
.imgAdd .d-flex {
  margin-top: 200px;
}

.button-container23 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button-container23 button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 7px;
  width: 100%;
}

.insdbtndiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.insdbtndiv p {
  margin: 0;
  color: #595959;
  font-weight: 500;
}

.activemainSettingItem p {
  color: #1f64ff;
}

.expiryDiv {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.pymtmthdOld {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.expiryDiv p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.expiryDiv img {
  cursor: pointer;
}

.pymtmthdNew button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pymtmthdNew button p {
  margin: 0;
  font-size: 14px;
}

.billHistory .custom-table {
  width: 100%;
  text-align: center;
}

.billHistory .custom-table .dateDivRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.billHistory .custom-table .dateDivRow p {
  margin: 0;
  font-weight: 500;
}

.billHistory .custom-table .dateDivRow img {
  cursor: pointer;
}

.billHistory .custom-table div p {
  margin: 0;
  font-weight: 500;
}

.billHistory .custom-table tr  div{
  margin-bottom: 1rem;
}


