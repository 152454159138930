.bookdemoHead h3 {
  font-weight: 600 !important;
}

.bookdemoHead {
  text-align: center;
  margin-bottom: 3rem;
}

.bookdemoForm_container {
  margin: 4rem auto;
}

.bookdemoInput input,
.bookdemoInput select {
  height: 40px;
  font-size: 15px;
  padding: 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
}

.bookdemoInput .react-international-phone-input-container {
  height: 40px;
  font-size: 15px;
  padding: 0 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
}

.react-international-phone-input-container .react-international-phone-input {
  border-left: 2.44px solid transparent !important;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
  background-clip: padding-box;
}

.bookdemoInput textarea {
  font-size: 15px;
  padding: 0.5rem;
  border: 2px solid transparent;
  background-clip: padding-box;
  border: 2.44px solid;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
}

.bookdemobTN button {
  color: #ffffff;
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%),
    linear-gradient(180deg, #00dad9 0%, #3876de 100%);
  width: 150px;
  border-radius: 30px;
  border: none;
}

.bookdemobTN button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.what_heading {
  font-weight: 500;
}

.bookdemobTN {
  margin: 3rem auto;
}

.happen_nxt_heading {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.What_happen_nxt_heading {
  border-bottom: 3px solid transparent;
  background-clip: padding-box;
  border-image-source: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  );
  border-image-slice: 1;
  width: fit-content;
  padding-bottom: 0.5rem;
}

.What_happen_nxt_heading_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsnext_container {
  margin: 2rem auto;
}

.whatsnextText {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.whatsnextText p {
  margin: 0;
  font-weight: 500;
}

.whatsnextText .numbox {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  font-weight: 500;
  color: #ffffff;
  height: fit-content;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.whatsnextTextwrapper {
  margin: 4rem auto;
}

.emptyBook {
  height: 100px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .bookdemoInputName {
    margin-bottom: 1.5rem;
  }
}

.modal-header {
  border-bottom: none !important;
}

.closeModay {
  border: none !important;
  background-color: transparent;
}

.closeFooterModal {
  background: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  ) !important;
}

.captchaText {
  user-select: none;
  /* Disable text selection */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
