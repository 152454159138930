.valueCardpad .valueCard {
  background-color: lightgrey;
}

.Bucketrad {
  background-color: white;
  width: fit-content;
  border-radius: 15%;
}

/* 30 days payment recieved css */

.valueCardpadPymntHstry .valueCardPymntHstry {
  background-color: white;
  width: 100%;
  height: 100%;
}

.valueCardpadPymntHstry {
  padding: 0 9px !important;
}

.valueCardpad1PymntHstry {
  padding: 15px !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.valueCardPymntHstry {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem 0.5rem;
  box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
}

.valueCardPymntHstry h4,
.valueCardPymntHstry p {
  color: rgba(52, 58, 64, 1);
}

.valueCard div img {
  width: 1.4rem;
  margin-top: -0.3rem;
}

.valueCardPymntHstry p {
  color: rgba(52, 58, 64, 1);
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 15px;
  font-weight: 500;
}

.debtorSearchPayHis {
  background: rgba(134, 57, 206, 1);
  border-radius: 14px;
  height: 420px;
}

.PayHis {
  margin-top: 40px !important;
}

.valueCardInsidePymntHstry {
  padding: 10px;
}

.valueCardInsidePymntHstry img {
  height: 80px;
}

.headblock {
  text-align: center;
}

.paymntRcvdCaRDS {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.valueCardInsidePymntHstry p {
  margin-bottom: 0.8rem;
  color: #343a40;
}

.img-fluid1 {
  width: auto !important;
}
.outdiv {
  background-color: white;
  border-radius: 8px;
}
.cibil {
  margin-left: 10px;
  gap: 1.5rem;
}
.radiotherDiv {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.radiotherDiv label {
  font-size: 13px;
}
.indiv {
  gap: 0.6rem;
}
.otherDiv label {
  margin-right: 20px;
}

.indiv h5 {
  margin-right: 10px;
}
.findcity {
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chechBoxDiv {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.filterDic {
  background-color: #ebebeb !important;
}

.cibiloutdiv {
  background-color: #ffffff;
  width: fit-content;
  padding: 0.7rem;
  border-radius: 8px;
}

.cibiloutdiv label {
  font-size: 13px;
  margin: 0;
}

.cibil {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.searchUser {
  height: 3.3rem;
}

.filterDivHead {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.3rem 0;
}

.filterDivHead h3 {
  margin: 0;
}

.bucketBtnsDiv img {
  width: 1.1rem !important;
}

.bucketBtnsDiv p {
  color: #b6b6b6;
}

.bucketBtnsDiv {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #4caf50 !important;
}
.butn {
  border: none;
}

.checkmark {
  height: 18px;
  cursor: pointer;
  width: 18px;
  background-color: #b3b3b3;
  border-radius: 50%;
  margin-top: 0.3rem;
}

input:checked ~ .checkmark {
  background-color: #4caf50;
}

.checkmark:after {
  content: "";
  position: absolute;
}

input:checked ~ .checkmark:after {
  display: block;
}

.btn_toggle1ani img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.checkmark:after {
  left: 7px;
  top: 7px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.filterDivHeadContiaenr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ---------------------------------------------------------------------------- */

.barChartLegendpink {
  width: 15px;
  height: 15px;
  background-color: #ecccff;
  border-radius: 50%;
}

.barChartLegendblue {
  width: 15px;
  height: 15px;
  background-color: #7e32cb;
  border-radius: 50%;
}

.barChartLegend p {
  font-size: 14px;
  color: #2c2c2c;
  margin: 0;
}

.barChartLegend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.barChartLegends {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
