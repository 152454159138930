* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  background: linear-gradient(
    180deg,
    #f7e8fc 0%,
    #f7e8fc 14.52%,
    #e4f8f3 23.85%,
    #f7e8fc 37.44%,
    #effbd7 53.55%,
    #f7e8fc 70.3%,
    #e5f6f3 83.93%,
    #f7e8fc 100%
  );
}
