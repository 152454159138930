.billInput {
    background-color: white;
    justify-content: center;
    padding: 2rem;
    border-radius: 15px;

    box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
}

.form-control input {
    margin: 2px;
}

.countybill {
    font: sans-serif;
    font-weight: 500 !important;
}

.ctyslct {
    border-radius: 5px;
    outline: none;
    background-color: white;
    opacity: 35%;
    padding: 5px;
}


.payInfo {
    background-color: white;
    justify-content: center;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
}

.payInf {
    margin-top: 10px;
}

.form-inline input {
    margin: 5px;
}

.cArD {

    margin-top: 10px;
    padding-left: 29px;
}

.sltcnty {
    padding-left: 18px;
    padding-right: 6px;
}

.txntr {
    text-align: center;
}

.almrg {
    margin-top: 5px;
}

.PIcrd {
    padding-left: 10px;
}

.piCRD {
    padding-right: 17px;
}

.ctyHead {
    color: black;
}

.PI {
    padding-right: 10px;
}

.paybtn .paybtn1{
    padding: 12px;
}

.payInf , .paybtn {
    margin-top: 30px;
}