.col-md-4 .column3 {
  margin-left: 25px;
}
.subText {
  margin-left: 20px;
  color: rgba(0, 0, 34, 0.5);
  font-size: 14px;
}
.connCalen {
  margin-top: 15px;
}
.accordion-header .profiledrop {
  background-color: none;
}
.inppass {
  margin: 10px;
  border: none;
  outline: none;
}
#checkbox1 #checkbox2 {
  margin-left: 10px;
}
.updButton {
  margin-top: 10px;
}
.chkbox {
  margin-left: 10px !important;
}
.imgAdd {
  margin-top: 10px;
  margin-left: 15px;
}
.custom-button {
  color: rgba(89, 89, 89, 1);
  font-weight: 700;
  display: grid;
  gap: 0px;
}

.txtbtn {
  margin-left: 120px;
  margin-right: 110px;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  border-bottom: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.profileInput input {
  border: none !important;
  margin-bottom: 0.5rem;
  height: 35px;
  padding: 0.5rem;
  outline: none;
  background-color: #f5f6fa;
  border-radius: 6px;
  font-weight: 500;
  width: 100%;
}

.connCalen button {
  font-size: 15px;
}

.accordion-body .custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.profileInput select {
  font-weight: 500;
  border: none;
  width: 99% !important;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: #f5f6fa;
  outline: none;
}

.acordianScroll {
  height: 505px;
  overflow-y: auto;
}
/* 
.accordion-button::after {
  background-color: #aab2c8;
  border-radius: 50%;
  font-size: 0.8rem !important;
  color: #ffffff !important;
  padding: 0.5rem !important;
  width: 1.5rem;
  height: 1.5rem !important;
  text-align: center;
}

.accordion {
  --bs-accordion-btn-icon: url("../../assets/images/accordionBtn.svg") !important;
} */
