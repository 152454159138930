.dashboard_wrapper {
  display: flex;
  width: 100%;
  height: 100% !important;
  align-items: stretch;
}

#content {
  width: 100% !important;
  min-height: 100%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.logo_msgCount .msgCount_div p {
  color: rgba(134, 142, 150, 1);
  font-weight: 500;
  font-size: 11px;
  white-space: nowrap;
  margin: 0;
}

.logo_msgCount .logo_div h4 {
  margin: 0;
  font-size: 15px;
}

.navbar_left {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.2rem;
}

.liveDiv {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.liveDiv p {
  margin: 0;
  color: rgba(95, 95, 95, 1);
}

.navbar_right {
  display: flex;
  align-items: center;
  border-left: 5px solid #f7e8fc;
  padding: 1rem;
  padding-right: 2rem;
}

.dashboard_wrapper {
  background-color: #f7e8fc !important;
}

.navbarRightAcc {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbarRightAcc img {
  width: 2.5rem;
  border-radius: 50%;
}

.navbarRightNoti {
  padding: 0.2rem 0.7rem;
  cursor: pointer;
}

.sidebar_wrapper {
  position: sticky;
  top: 0;
}

.centerNav {
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

.LEFTalINGdROPDOWN {
  left: -82%;
  top: 160% !important;
}

.overviewHead {
  margin: 2rem 0rem;
  padding: 0 14px;
}

.valueCard {
  background-color: #ffffff !important;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.valueCard h4,
.valueCard p {
  color: rgba(52, 58, 64, 1);
}

.valueCard h4 {
  font-weight: 600;
}

.valueCard div img {
  width: 1.4rem;
  margin-top: -0.3rem;
}

.valuTxt {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.valuTxt h4 {
  margin: 0;
}

.valueCard p {
  color: rgba(52, 58, 64, 1);
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 15px;
  font-weight: 500;
}
.moreOption {
  cursor: pointer;
}
.bottomLineP {
  font-size: 9px !important;
}

.overviewContaINER {
  padding: 10px;
}

.revenueCall_container {
  background-color: #ffffff !important;
  border-radius: 10px;
}

.revenueDIv {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
  border-right: 1px solid rgba(167, 167, 167, 1);
  cursor: pointer;
}

.noneBorderDiv {
  border: none;
}

.revenueText p {
  margin: 0;
  font-weight: 500;
}

.revenueText .rtext2 {
  font-size: 12px;
  color: rgba(134, 142, 150, 1);
  white-space: nowrap;
}

.outAmountDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.outAmountDiv p {
  color: rgba(190, 47, 37, 1);
  font-weight: 500;
  margin: 0;
}

.outAmountDiv h4 {
  color: rgba(52, 58, 64, 1);
  font-weight: 600;
}

.revenueCallHead {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(167, 167, 167, 1);
}

.collecBAr {
  background: rgba(132, 94, 247, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem;
  margin-bottom: 1rem;
  height: 100%;
}

.collecBAr1 {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 231, 237, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  gap: 1rem;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .collecBAr1 {
    gap: 0.8rem;
  }

  .circleProgBar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.5rem !important;
  }

  .collecBAr {
    margin-bottom: 0;
  }
}

.collecBAr .persntTetx {
  color: #ffffff;
}

.collecBAr1 .persntTetx {
  color: rgba(52, 58, 64, 1);
}

.collecBAr .persntTetx p,
.collecBAr1 .persntTetx p {
  margin: 0;
  font-size: 13px;
}

.revenueCallFoot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
}

.revenueCallFoot button {
  background-color: transparent;
  border: none;
}

.valueCardpad {
  padding: 0 9px !important;
}

.revenueCallHead2 {
  justify-content: space-between !important;
  background-color: #ffffff;
  padding: 1.2rem 0 !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.lineChartCOl .canvas {
  width: 100% !important;
  height: 100% !important;
}

.lineChartCOl {
  margin-top: 2rem !important;
}

.chillin_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(227, 249, 191, 1);
  padding: 1rem;
  border-radius: 8px;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.chillText h6 {
  margin: 0.2rem;
}

.chillText p {
  font-size: 12px;
  margin: 0;
}

.chillin {
  padding: 0 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.csllListItm_right img {
  width: 1.1rem;
}

.csllListItm_right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.csllListItm_right p {
  font-size: 25px;
  font-weight: 600;
  color: rgba(52, 58, 64, 1);
  margin: 0;
}

.csllList {
  padding: 0 10px;
}

.csllListItm_left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.csllListItm_left p {
  font-size: 15px;
  margin: 0;
}

.csllListItm_left h6 {
  margin: 0;
}

.csllList ul {
  padding: 0;
  list-style: none;
}

.csllList li {
  margin: 1rem 0 !important;
}

.recentAlrts_container {
  margin: 1rem;
}

.csllListItm {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.callRecordContainer {
  background-color: #ffffff;
  border-radius: 10px;
}

.callRecord {
  border-radius: 10px !important;
  height: 100%;
}

.callRecordHead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.graphsRevenueDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.revenueCall {
  padding-left: 10px;
}

.offgreen,
.orangegreen,
.lightgreen,
.Fullgreen {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.Fullgreen {
  background-color: rgba(43, 180, 106, 1);
}

.lightgreen {
  background: rgba(87, 236, 156, 1);
}

.orangegreen {
  background: rgba(250, 180, 141, 1);
}

.offgreen {
  background: rgba(169, 251, 207, 1);
}

.valueCardpad1 {
  padding: 0 !important;
}

.Dashpages_contaioner {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.progressBAr1 {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 0.5rem 0;
  height: 13px;
  width: 100%;
}

.progress-done {
  background: rgba(0, 112, 192, 1);
  box-shadow: rgba(0, 112, 192, 1);
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.countryRecovry ul {
  padding: 0;
  list-style: none;
}

.countryRecovry ul li {
  margin-bottom: 1rem !important;
}

.cntryRcvryItemLeftHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cntryRcvryItemLeftHead h6 {
  margin: 0;
}

.cntryRcvryItemRigthHead p,
.cntryRcvryItemLeftHead p {
  white-space: nowrap;
  color: rgba(134, 142, 150, 1);
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}

.countryRecovry {
  height: 100%;
}

.countryRecovry ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.countryRecovryItem {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

.cntryRcvryItemLeft {
  width: 100%;
}

.rcvrypercnt {
  margin-top: 4px !important;
}

.usersContainer {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 0.5rem;
}

.mapLocation {
  padding: 1rem;
}

.uploadCSVBtn {
  background-color: rgba(214, 214, 214, 1);
  padding: 0.3rem 0.8rem;
  border-radius: 6px;
  color: rgba(52, 58, 64, 1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.addDebtorBtn {
  background-color: rgba(93, 54, 234, 1);
  padding: 0.3rem 0.8rem;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.addDebtorBtn:hover,
.uploadCSVBtn:hover {
  transform: scale(1.1);
}

.overviewHeadCntnt p {
  margin: 0;
  color: rgba(52, 58, 64, 1);
}

.overviewHeadCntnt {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  background-color: #ffffff;
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.overviewHeaddp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.debtordetailCardBody {
  display: flex;
  gap: 0.5rem;
}

.debtordetailCardBody img {
  width: 1.4rem;
  margin-top: -4px;
}

.debtordetailCardBody h4 {
  margin: 0;
}

.debtordetailCardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.debtordetailCardHead h6 {
  margin: 0;
}

.debtordetailCard {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 7px;
  margin-bottom: 1rem;
}

.chatDataDiv {
  margin-top: 1.5rem;
}

.dflexMap {
  display: flex !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.checkbox-item {
  display: flex;
  align-items: center;
}

.checkbox-item label p {
  margin: 0;
}

.genderAndAgeDiv {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.catagoriesfilter {
  background-color: #ffffff;
  padding: 1rem;
  margin: 0.5rem 10px;
  border-radius: 7px;
}

.cate {
  margin: 1rem 0;
}
.nav-link {
  font-weight: 500;
}

.date_filter_div {
  box-shadow: 0px 2px 12px 0px #25334226;
  border-radius: 7px;
}

.piechatrDiv {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 10px;
}

.recoveryPercent p,
.disputePercent p,
.L2Percent p {
  font-weight: 700;
  margin: 0;
  font-size: 25px;
}

@media screen and (min-width: 1700px) {
  .recoveryPercent {
    color: #5832e9;
    position: absolute;
    right: 15% !important;
    bottom: 2%;
  }

  .disputePercent {
    color: #0070c0;
    position: absolute;
    left: 16% !important;
    bottom: 2%;
  }

  .L2Percent {
    color: #f049a2;
    position: absolute;
    right: 25% !important;
    top: -7%;
  }
}

.recoveryPercent {
  color: #5832e9;
  position: absolute;
  right: 5%;
  bottom: 2%;
}

.disputePercent {
  color: #0070c0;
  position: absolute;
  left: 5%;
  bottom: 2%;
}

.L2Percent {
  color: #f049a2;
  position: absolute;
  right: 18%;
  top: -7%;
}

@media screen and (max-width: 1400px) {
  .recoveryPercent {
    color: #5832e9;
    position: absolute;
    right: -1% !important;
    bottom: 1%;
  }

  .disputePercent {
    color: #0070c0;
    position: absolute;
    left: -1% !important;
    bottom: 1%;
  }

  .L2Percent {
    color: #f049a2;
    position: absolute;
    right: 7% !important;
    top: -8%;
  }
}

@media screen and (max-width: 1350px) {
  .legendsRow {
    flex-direction: column !important;
  }
}

.recoveryColor,
.L2Color,
.disputeColor,
.pendingColor {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.recoveryColor {
  background-color: #5832e9;
}

.L2Color {
  background-color: #f049a2;
}

.disputeColor {
  background-color: #0070c0;
}

.pendingColor {
  background-color: #dcd9e8;
}

.legendDetail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.legendDetail p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
}

.legendsContainer {
  margin-top: 1rem;
}

.piechatrDiv canvas {
  width: 100% !important;
  height: 100% !important;
}

.highcharts-root {
  height: 100% !important;
}

.brandingContainer {
  margin: 2rem;
  text-align: right;
}

/* ------------------------------------------------------------------------------------------------------- */

.custMob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.customerid {
  display: flex;
  align-items: center;
}

.customerid p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  padding: 0 !important;
}

.modalHeadPopUp {
  display: flex;
  justify-content: space-between !important;
}

.VLine {
  padding: 0 1rem !important;
}

.VLine img {
  padding: 0 !important;
}

.searchdataTable_container table tbody tr td,
.searchdataTable_container table thead tr th {
  padding: 0 !important;
  border: none !important;
}

.searchdataTable_container table thead tr th div {
  background-color: #4e4e4e;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.searchdataTable_container table thead tr th div p {
  color: #ffffff;
  font-weight: 500;
  margin: 0;
  font-size: 15px;
}

.colLast {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.colFirst {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.searchdataTable_container table tbody tr td div {
  background-color: #ffffff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.searchdataTable_container table tbody tr td div p {
  color: #4e4e4e;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}

.searchdataTable_container table {
  background-color: #ebebeb !important;
  --bs-table-bg: #ebebeb !important;
}

.logoutIconandDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.logoutIconandDiv p {
  margin: 0;
  font-weight: 500;
}

.logoutIconandDiv img {
  width: 1.4rem;
}

table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  display: none !important;
}

table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  display: none !important;
}

/* --------------------------------------Full screen code------------------------------------------------------ */

select {
  cursor: pointer !important;
}

.usersContainer.zoomedIn {
  transform: scale(0.9); /* Adjust the scale factor as needed for zoom-in */
  transition: transform 0.3s ease-in-out;
}

.col-12.zoomedIn {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background: rgba(
    0,
    0,
    0,
    0.8
  ) !important; /* Adjust the background color and transparency as needed for zoom-in */
  z-index: 1000 !important; /* Adjust the z-index as needed for zoom-in */
  transition: all 0.3s ease-in-out;
}

.revenueCall_container.zoomedIn {
  transform: scale(0.9); /* Adjust the scale factor as needed for zoom-in */
  transition: transform 0.3s ease-in-out;
}

.callRecordContainer.zoomedIn {
  transform: scale(0.9); /* Adjust the scale factor as needed for zoom-in */
  transition: transform 0.3s ease-in-out;
}

.col-12.zoomedIn {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background: rgba(
    0,
    0,
    0,
    0.8
  ) !important; /* Adjust the background color and transparency as needed for zoom-in */
  z-index: 1000 !important; /* Adjust the z-index as needed for zoom-in */
  transition: all 0.3s ease-in-out;
}

.dateOntainer456 {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.custom-modal3 .modal-content {
  width: 100% !important;
} 
