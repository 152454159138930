.login_wrapper {
  background-color: #f7e8fc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.login_container {
  background-color: #ffffff;
  border-radius: 12px;
}

.login_container .logo_div img {
  width: 120px;
  margin-bottom: 2rem;
}

.login_container .input_fields {
  text-align: left;
}

.login_container .input_fields label {
  font-size: 20px;
  margin-bottom: 0.3rem;
  font-weight: 450;
}

.login_container .input_fields input {
  width: 100%;
  height: 38px;
  /* margin-bottom: 1rem; */
  font-size: 16px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1px 3px 10px !important;
  background-color: rgba(238, 236, 236, 1);
  border-radius: 6px;
  padding: 0.6rem;
}

.login_container .login_btn {
  background-color: rgba(126, 50, 203, 1);
  padding: 0.5rem 0;
  width: 120px;
  margin-top: 1.5rem;
  color: white;
}

.login_container .login_btn a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
}

.login_container .login_btn:hover {
  background-color: rgba(126, 50, 203, 1);
  padding: 0.5rem 0;
  width: 120px;
  margin-top: 1.5rem;
}

.login_container .login_btn:focus-visible,
.login_container .login_btn:hover :focus {
  outline: auto !important;
}

.login_password {
  height: 38px;
  position: relative;
}

.eye_btn_ {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 7px;
  right: 6px;
}
.dashboardLoginName h4 {
  color: rgba(167, 0, 255, 1);
  font-weight: 500;
}

.dashboardLoginName h4 span {
  color: #fc912b;
}

.dashboardLoginName h6 {
  color: #00002299;
  font-weight: 500;
}
.login_btn_div {
  margin-top: 1rem;
}

.dashboardLoginName {
  margin-bottom: 0.5rem;
}

.dashboardLoginNameInputFields label {
  font-size: 15px !important;
  padding-left: 0.6rem;
  color: #343a40;
  margin-top: 1rem;
}

.dashboardLoginNameInputFields input {
  outline: none;
}

.err_msg {
  color: red !important;
  font-size: 12px !important;
  text-align: left !important;
}
