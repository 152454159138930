.debtorSearch {
  border-radius: 14px;
  /* padding: 0 !important; */
}

.debtorFilterContainer {
  background: rgba(134, 57, 206, 1);
  text-align: center;
  padding: 10px 1.3rem;
  border-radius: 14px;
}

.debtorFilterContainer h5 {
  margin-top: 5px;
  color: #fff;
  margin-bottom: 1rem;
}

.debtorFilterContainer button {
  margin-top: 1.4rem;
  margin-bottom: 10px;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background: rgba(247, 232, 252, 1);
  box-shadow: inset;
  border: none;
  box-shadow: 0.5px 0.1px;
  justify-content: center;
  align-items: center;
}

.debtorFilterContainer .inp {
  padding: 0 0.5rem;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  border: none;
  font-weight: 500;
}

.daysListBtn {
  border: none;
}

.daysListView {
  background: #fff;
  border-radius: 10px;
}

.daysListView a {
  color: black;
  font-family: Roboto;
}

.Search_btn p {
  margin: 0;
}

.silash {
  color: rgba(126, 50, 203, 1);
}

.overviewHeadLeftContn {
  position: relative;
}

.daysListView {
  width: 96%;
  position: absolute;
  top: 58px;
}

.daysListView ul {
  padding: 0 !important;
  text-align: center;
  list-style: none;
  margin-top: 1rem;
}

.daysListView ul li {
  margin: 0.4rem auto;
}

.promToPay .valueCard {
  background-color: #fff !important;
  padding: none !important;
}

.promToPay .valueCardpad {
  background-color: #fff !important;
  padding: none !important;
}

.modal-dialog {
  justify-content: center !important;
}

.modal-content {
  width: 80%;
  border-radius: 2.5%;
}

.rangeSlider {
  margin-bottom: 15px;
}

.fil {
  font-size: 0.8rem;
}
.radio-item label {
  margin-left: 10px;
}

.checkbox-label {
  margin-left: 10px;
}

.Filter {
  background-color: #fff;
}

/* .radio-container h6 {
  margin: 0;
  margin-top: 10px;
} */

/* Range slider */

.middle {
  position: relative;
  width: 50%;
  max-width: 500px;
}

.slider {
  position: relative;
  z-index: 1;
  height: 10px;
  margin: 0 15px;
}

.slider > .track {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: grey;
}

.slider > .range {
  position: absolute;
  z-index: 2;
  left: 25%;
  right: 25%;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #48db66;
}

.slider > .thumb {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  background-color: #ae81d7;
  border-radius: 50%;
}

.slider > .thumb.left {
  left: 25%;
  transform: translate(-15px, -10px);
}

.slider > .thumb.right {
  right: 25%;
  transform: translate(15px, -10px);
}

input[type="range"] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 2;
  height: 50px;
  width: 100%;
  opacity: 0;
}

input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 30px;
  height: 30px;
  border-radius: 0;
  border: 0 none;
  background-color: red;
  cursor: pointer;
  -webkit-appearance: none;
}

.cate h6 {
  font-size: 0.8rem;
}

.vec {
  margin-right: 30px;
}

.genderRadioInputs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ageandGenderFIlter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 1rem 0;
  gap: 2rem;
}
