.termsOfUseHeading {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  font-weight: 700;
  width: fit-content;
}

.termsOfUse_container_wrapper {
  margin: 4rem auto;
}

.termsOfUse_content p {
  color: rgba(52, 58, 64, 1);
  margin: 1rem 0;
}

.termsOfUse_content h4 {
  margin-top: 2rem !important;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  width: fit-content;
}

.termsOfUseHeading {
  margin-bottom: 3rem;
}
