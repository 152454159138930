.nafia_hide {
  position: fixed;
  top: 0;
  z-index: 999999999;
  padding-top: 0.8rem;
  display: none;
}

.nafia_show {
  display: block !important;
  width: 100% !important;
}

.navIemTextSelected a {
  background: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  ) !important;
  background-clip: text !important;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-size: 100% 100% !important;
}

.home {
  font-family: "Poppins", sans-serif !important;
}

.textBtnHeader {
  cursor: pointer;
}

.header_container {
  position: relative;
}

.header_container .navbar {
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
  background: rgba(247, 232, 252, 1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.header_container .navbar-collapse {
  flex-grow: unset !important;
  gap: 1rem;
  width: 60%;
}

.prdctList {
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.navbar-brand img {
  width: 200px;
}

@media screen and (max-width: 1250px) {
  .header_container .navbar-collapse {
    width: 65%;
  }
}

@media screen and (max-width: 1170px) {
  .header_container .navbar-collapse {
    width: 68%;
  }

  .header_container .navbar {
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 1070px) {
  .header_container .navbar-collapse {
    width: 70%;
  }

  .header_container .navbar {
    padding: 0.5rem 1.8rem;
  }

  .navbar-nav2 button {
    font-size: 14px !important;
  }

  .header_container .navbar-collapse .navbar-nav {
    font-size: 14px !important;
  }

  .navbar-brand img {
    width: 190px;
    height: 49px;
  }
}

@media screen and (max-width: 600px) {
  .header_container .navbar {
    padding: 0.5rem 1.5rem;
  }

  .navbar-brand img {
    width: 150px;
    height: 39px;
  }

  .nafia_btn_div {
    width: 150px;
  }
}

.navbar-nav1 {
  width: 100%;
  justify-content: space-between;
}

.navbar-nav1 li a {
  color: #000;
}

.navbar-nav2 button {
  white-space: nowrap;
  font-weight: 500;
  font-size: 15px;
  outline: none;
  font-family: "Poppins", sans-serif !important;
}

.navbar-nav2 {
  gap: 1rem !important;
}

.careerBtn {
  display: inline-flex;
  border: double 2px transparent;
  border-radius: 80px;
  background-image: linear-gradient(#ffffff, #ffffff),
    radial-gradient(circle at top left, #be2f25 14.86%, #7e32cb 115.47%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #54c0eb;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s ease-in;
  width: 100%;
  margin: 0 0.9rem;
  height: 34px;
}

.careerBtn:hover {
  background-image: linear-gradient(#f7e8fc, #f7e8fc),
    radial-gradient(circle at top left, #be2f25 14.86%, #7e32cb 115.47%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.bookBtn {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  color: #ffffff;
  padding: 0.4rem 0.9rem;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease-in-out;
}

.bookBtn:hover .spanArrow {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 0.1rem 0.2rem 0.2rem 0.2rem;
  transition: all 0.3s ease-in-out;
}

.header_container .navbar-collapse .navbar-nav {
  align-items: flex-end;
  font-weight: 500;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.header_container .navbar .navbar-toggler {
  border-width: 2px !important;
  outline: none;
}

/* Style the navigation menu */
.navbar-nav1 {
  display: flex;
  list-style: none;
  padding: 0 0.7vw;
}

.navbar-nav1 li {
  position: relative;
  margin: 0 10px;
  cursor: pointer;
}

.navbar-nav1 li::before {
  content: "";
  display: block;
  height: 2px;
  width: 0;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.navbar-nav1 li:hover::before {
  width: 100%;
}

.nafia_btn_div {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 220px;
  height: 150px;
  z-index: 999999999999999 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #ffffff;
}

.nafia_btn_div video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* -------------------------------------------------------------------------------------------------- */
.welcome_screen {
  /* margin-top: 6rem !important; */
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_screen_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 1600px) {
  .audio_container {
    position: relative;
    height: 370px !important;
  }
}

@media screen and (max-width: 1400px) {
  .audio_container {
    position: relative;
    height: 340px !important;
  }
}

@media screen and (max-width: 1200px) {
  .audio_container {
    position: relative;
    height: 320px !important;
  }
}

@media screen and (max-width: 991px) {
  .video_container h6 {
    font-size: 15px !important;
  }

  .home_content {
    margin: 0 1rem;
  }

  .welcome_screen {
    height: 40vh;
  }
}

@media screen and (max-width: 768px) {
  .video_container h6 {
    font-size: 14px !important;
  }

  .audio_container .audio_playBtn {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 18px !important;
  }

  .video_container .video_playBtn {
    padding: 0.2rem 1.5rem !important;
    border-radius: 7px !important;
  }

  .video_container .video_playBtn img {
    height: 1rem !important;
  }

  .audio_container {
    position: relative;
    height: 290px !important;
  }
}

@media screen and (max-width: 550px) {
  .video_container h6 {
    font-size: 11px !important;
    width: 100% !important;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  .welcome_screen {
    margin-top: 3rem !important;
  }

  .audio_container {
    height: 240px !important;
  }

  .audio_container .audio_playBtn {
    width: 2.5rem !important;
    height: 2.5rem !important;
    border-radius: 15px !important;
  }

  .video_container .video_playBtn {
    padding: 0.1rem 1.3rem !important;
    border-radius: 7px !important;
  }

  .video_container .video_playBtn img {
    height: 0.8rem !important;
  }
}

@media screen and (max-width: 450px) {
  .audio_container {
    height: 150px !important;
  }

  .nafia_btn_div {
    width: 120px;
    bottom: 1.5rem;
    right: 1.5rem;
  }
}

.video_container h6 {
  margin-bottom: 1rem;
}

.welcome_screen_container .wlcm_heading {
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  margin: 0;
}

.video_container {
  text-align: center;
}

.video_container .video_playBtn {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  border: none;
  padding: 0.3rem 1.7rem;
  border-radius: 7px;
}

.video_container .video_playBtn img {
  height: 1.1rem;
}

.audio_container {
  position: relative;
  height: 400px;
}

.audio_container img {
  width: 100%;
  height: 100%;
}

.audio_container .audio_playBtn {
  background: transparent;
  border-radius: 24px;
  width: 3.5rem;
  height: 3.5rem;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.wlcm_heading_div {
  text-align: center;
}

.wlcm_heading_div_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background: url("../assets/images/Ellipse.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100% !important;
}

/* ------------------------------------------------------------------------------------------------ */

section.solution,
section.challenges {
  margin-top: 6rem;
}

.challenges_container .left_challenge h4 {
  font-weight: 700;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.active-linknav {
  background: linear-gradient(
    91.62deg,
    #7e32cb 14.86%,
    #be2f25 115.47%
  ) !important;
  background-clip: text !important;
  color: transparent !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-size: 100% 100% !important;
}

.challenges_container .left_challenge h6 {
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 1px;
  margin: 1rem 0;
}

.challenges_container .chalenges_list {
  list-style: none;
}

.challenges_container .chalenges_list .chalenges_item {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  width: fit-content;
  padding: 0.2rem 0.8rem;
  border-radius: 20px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.challenges_container .chalenges_list .chalenges_item:hover,
.challenges_container .chalenges_list .chalenges_item.slctdItem {
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.challenges_container .vertical_gradient {
  width: 4px;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(
    #f29cf6 0.73%,
    #7e32cb 20.89%,
    #54c0eb 38.47%,
    #afd371 58.12%,
    #ea7b5b 78.8%,
    #8f4699 100%
  );
}

.challenges_container .challenges_image {
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 auto !important;
  padding: 1.5rem 1rem;
}

.challenges_container .challenges_image h6 {
  font-weight: 600;
}

.challenges_container .challenges_image .challenges_image_container img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .challenges_container .left_challenge h6 {
    font-size: 14px;
    line-height: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  section.solution,
  section.challenges {
    margin-top: 4rem;
  }

  .challenges_container .vertical_gradient {
    width: 100%;
    height: 4px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(
      270deg,
      #f29cf6 0.73%,
      #7e32cb 20.89%,
      #54c0eb 38.47%,
      #afd371 58.12%,
      #ea7b5b 78.8%,
      #8f4699 100%
    );
  }
}

@media screen and (max-width: 576px) {
  .challenges_container .chalenges_list {
    list-style: none;
    padding: 0;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------------- */

section.solution {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  padding: 4rem 0;
}

.solution_container .solution_text p {
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 15px;
}

.solutions_cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
}

.solutions_cards .cards {
  text-align: center;
}

.solutions_cards .cards p {
  color: #ffffff;
  font-size: 14px;
  margin: 1rem 0;
  width: 112px;
}

.solutions_cards .cards img {
  width: 7rem;
  height: 7rem;
}

.solutions_cards_lg {
  margin-top: -2rem !important;
}

.Box_section_points {
  font-weight: bold;
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .solutions_cards .cards img {
    width: 5rem;
    height: 5rem;
  }

  section.solution {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .solutions_cards {
    justify-content: space-around;
  }

  .solutions_cards_resp {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 576px) {
  .solutions_cards .cards p {
    color: #ffffff;
    font-size: 11px;
    margin: 1rem 0;
    width: 112px;
  }

  .solutions_cards .cards img {
    width: 3.5rem;
    height: 3.5rem;
  }

  .solution_container .solution_text p {
    color: #ffffff;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 1.5rem;
    font-size: 14px;
  }

  .solutions_cards {
    margin: 2rem 0;
  }

  .solutions_cards_resp {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .home_content {
    margin: 0 0.6rem !important;
  }

  .challenges_container .left_challenge h6 {
    font-size: 14px;
    line-height: normal;
  }

  section.solution {
    margin-left: -0.6rem;
    margin-right: -0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .solutions_cards .cards p {
    width: 100px;
  }

  .solutions_cards_resp {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.empty_div {
  height: 200px !important;
  width: 100%;
}

/* --------------------------------------------------------------------------------------------------------------------------- */

.talkGpt h4,
.aboutNafia_text h4 {
  font-weight: 700;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  margin-bottom: 0px;
}

.aboutNafia_text h4 {
  margin-bottom: 10px;
}

.talkGpt p,
.aboutNafia_text p {
  width: 65%;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5rem;
  margin: 1.5rem 0;
}

.aboutNafia_text {
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  padding: 5rem 3rem;
  border-radius: 30px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
}

.aboutNafia_text li {
  margin-bottom: 1rem;
}

.nafiaprdctText {
  background: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 3rem 0 !important;
}

.nafiaprdctText p {
  width: 100% !important;
}

.nafiaprdctText h4 {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

section.aboutNafia {
  margin-top: -10%;
}

.aboutNafia_container {
  position: relative;
}

.nafia_22 {
  position: absolute;
  right: 4%;
  top: 70%;
  margin-right: 0;
  margin-left: auto;
}

.shadowBottom {
  margin-right: -35px;
  margin-top: -0.6rem;
}

.nafia_22 img {
  width: 100%;
}

.talkGpt {
  padding: 3rem;
}

.talkGptBlack {
  background-color: rgba(41, 41, 41, 1) !important;
  margin-bottom: 6rem;
}

.talkGptBlack h4 {
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
}

.talkGptBlackPadding {
  padding: 3rem 0 !important;
}

.talkGptBlack p {
  color: #ffffff !important;
  width: 80% !important;
}

.lets_talk_btn button {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  border: none;
  color: #ffffff;
  padding: 0.5rem 1.1rem;
  border-radius: 30px;
  transition: 0.2s all ease-in;
}

.lets_talk_btn button:hover {
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1400px) {
  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 74%;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (max-width: 1200px) {
  .empty_div {
    height: 130px !important;
    width: 100%;
  }

  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 70%;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (max-width: 991px) {
  .empty_div {
    height: 100px !important;
    width: 100%;
  }

  .nafia_2 {
    position: absolute;
    right: 4%;
    top: -5%;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGpt p,
  .aboutNafia_text p {
    width: 65%;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3rem;
    margin: 1.5rem 0;
  }

  section.aboutNafia {
    margin-top: -15% !important;
  }

  .aboutNafia_text {
    padding: 3rem 3rem;
  }

  .aboutNafia_text h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .empty_div {
    height: 100px !important;
    width: 100%;
  }

  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 71% !important;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGpt p,
  .aboutNafia_text p {
    width: 65%;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.1rem;
    margin: 1.5rem 0;
  }

  .aboutNafia_text li {
    font-size: 13px;
  }

  .productRow1 {
    flex-direction: column-reverse !important;
  }

  section.aboutNafia {
    margin-top: -25% !important;
  }

  .aboutNafia_text {
    padding: 3rem 2rem;
  }

  .talkGpt {
    padding: 3rem 2rem;
  }

  .aboutNafia_text h4 {
    font-size: 20px;
  }

  .talkGptBlack {
    margin-bottom: 3rem !important;
  }
}

@media screen and (max-width: 720px) {
  .empty_div {
    height: 100px !important;
    width: 100%;
  }

  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 74% !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (max-width: 690px) {
  .empty_div {
    height: 100px !important;
    width: 100%;
  }

  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 76% !important;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGptBlack p {
    width: 100% !important;
  }
}

@media screen and (max-width: 620px) {
  .empty_div {
    height: 100px !important;
    width: 100%;
  }

  .nafia_22 {
    position: absolute;
    right: 4%;
    top: 78% !important;
    margin-right: 0;
    margin-left: auto;
  }
}

@media screen and (max-width: 576px) {
  .aboutNafia_text p {
    width: 100%;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1rem;
    margin: 1.5rem 0;
  }

  .nafia_22 {
    position: absolute;
    right: 5%;
    top: 80% !important;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGpt p,
  .aboutNafia_text p {
    margin: 1rem 0;
  }

  .aboutNafia_text {
    padding: 2.5rem 1rem;
  }

  .aboutNafia_text ul {
    padding: 0;
    list-style: none;
  }
}

@media screen and (max-width: 500px) {
  .aboutNafia_text p {
    width: 100%;
    letter-spacing: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1rem;
  }

  .talkGpt p,
  .solution_container .solution_text p {
    letter-spacing: normal;
  }

  .talkGpt h4,
  .aboutNafia_text h4 {
    font-size: 20px;
  }

  .nafia_2 {
    position: absolute;
    right: 10%;
    top: 50%;
    margin-right: 0;
    margin-left: auto;
  }

  .nafia_22 {
    position: absolute;
    right: 5%;
    top: 85% !important;
    margin-right: 0;
    margin-left: auto;
  }

  section.aboutNafia {
    margin-top: -34% !important;
  }

  .talkGpt {
    padding: 3rem 0;
  }

  .lets_talk_btn button {
    font-size: 14px;
    padding: 0.4rem 1rem;
  }
}

@media screen and (max-width: 480px) {
  .nafia_22 {
    position: absolute;
    right: 5%;
    top: 90% !important;
    margin-right: 0;
    margin-left: auto;
  }

  section.aboutNafia {
    margin-top: -40% !important;
  }
}

@media screen and (max-width: 400px) {
  .nafia_2 {
    position: absolute;
    right: 9%;
    top: 63%;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGpt p,
  .aboutNafia_text p,
  .solution_container .solution_text p {
    font-size: 12px !important;
  }

  .talkGpt h4,
  .aboutNafia_text h4 {
    font-size: 19px;
  }

  .aboutNafia_text ul li {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .nafia_2 {
    position: absolute;
    right: 9%;
    top: 67%;
    margin-right: 0;
    margin-left: auto;
  }

  .talkGpt p {
    font-size: 11px !important;
    line-height: normal;
  }

  .talkGpt h4 {
    font-size: 17px;
  }

  .lets_talk_btn button {
    font-size: 12px;
    padding: 0.4rem 1rem;
  }
}

/* ------------------------------------------------------------------------------------------------------------------------- */

.testimonial_text {
  padding: 2rem 0;
}

.testimonial_text .testi_head {
  list-style: none;
  padding: 0;
  text-align: right;
}

.testimonial_text .testi_head h5 {
  font-weight: 600;
  margin-top: 0.1rem;
}

.testimonial_text .testi_text {
  color: rgba(48, 48, 48, 1);
  letter-spacing: 1px;
  font-size: 15px;
  line-height: 1.8rem;
}

.testimonial_text .testimonial_text_row {
  margin: 1.5rem 0 !important;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 991px) {
  .testimonial_text .testi_text {
    font-size: 14px;
    line-height: 1.3rem;
    letter-spacing: normal;
  }

  .testimonial_text .testi_head h5 {
    font-weight: 600;
    margin-top: 0.1rem;
    font-size: 18px !important;
  }
}

.head_col {
  padding: 0 12px;
}

.testiCOl_col {
  padding: 0 12px;
}

@media screen and (max-width: 576px) {
  /* .head_col {
    padding: 0 !important;
  } */

  .testimonial_text .testimonial_text_row {
    margin: 1.5rem 0 !important;
    flex-direction: column;
  }

  .testimonial_text .testi_head {
    text-align: left;
  }
}

.testi_slider_container {
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  box-shadow: 0px 0px 55px 0px rgba(225, 194, 255, 1);
  border-radius: 30px;
  padding: 3rem 2rem;
  position: relative;
}

.testi_slider_container .item h5 {
  margin-bottom: 3rem;
}

.testi_slider_container .item .testiItemCont h5 {
  margin: 1rem;
  font-weight: 600;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.testi_slider_container .item .testiItemCont h6 {
  font-size: 15px !important;
  letter-spacing: 1px;
}

.testiContent {
  display: flex;
  margin: 4rem 0 3rem 0;
  margin-bottom: 0;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 1.8rem;
  position: relative;
}

.testi_slider_container .item .textiImg {
  border-radius: 50%;
  width: 7rem !important;
  height: 7rem;
}

.testi_slider_container .testiItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testiItemCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testi_slider_container .owl-theme .owl-dots {
  display: none;
}

.testi_slider_container .owl-theme .owl-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 33%;
  width: 100%;
}

.owl-carousel .owl-nav button.owl-prev {
  margin-left: 5rem;
}

.owl-carousel .owl-nav button.owl-prev:hover {
  background-color: transparent;
  color: #000;
}

.owl-carousel .owl-nav button.owl-next {
  margin-right: 5rem;
}

.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
  color: #000;
}

.owl-carousel .owl-nav button.owl-prev span {
  font-size: 4rem;
}

.owl-carousel .owl-nav button.owl-next span {
  font-size: 4rem;
}

.left_qoute {
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: -12%;
  top: -16%;
}

.left_qoute .qouteImg {
  width: 100% !important;
}

.right_qoute {
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: -8%;
  bottom: 8%;
}

.right_qoute .qouteImg {
  width: 100% !important;
}

@media screen and (max-width: 991px) {
  .owl-carousel .owl-nav button.owl-next {
    margin-right: 2rem;
  }

  .owl-carousel .owl-nav button.owl-prev {
    margin-left: 2rem;
  }

  .left_qoute {
    width: 2rem;
    height: 2rem;
  }

  .right_qoute {
    width: 2rem;
    height: 2rem;
  }

  .testi_slider_container .item .testiItemCont h6 {
    font-size: 15px !important;
    letter-spacing: 1px;
  }

  .testiContent {
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .owl-carousel .owl-nav button.owl-next {
    margin-right: 0;
  }

  .owl-carousel .owl-nav button.owl-prev {
    margin-left: 0;
  }

  .left_qoute {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: -5%;
    top: -10%;
  }

  .right_qoute {
    width: 1rem;
    height: 1rem;
    position: absolute;
    right: -5%;
    bottom: 8%;
  }

  .testi_slider_container .owl-theme .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 40%;
    width: 100%;
  }

  .testi_slider_container {
    padding: 3rem 1rem;
  }

  .testi_slider_container .item .testiItemCont h6 {
    font-size: 15px !important;
    letter-spacing: 1px;
  }

  .testiContent {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .testi_slider_container .item .testiItemCont h6 {
    font-size: 14px !important;
    letter-spacing: normal !important;
  }

  .testiContent {
    font-size: 14px;
    letter-spacing: normal !important;
    line-height: 1.3rem;
    margin-top: 2.5rem !important;
  }
}

/* ---------------------------------------------------------------------------------------------------------------------- */

section.blog {
  margin: 7rem 0 5rem 0;
}

section.blog .card {
  flex: 1;
  border: 1px solid #ccc;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  transform: scale(0.9);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

section.blog .card:hover {
  transform: scale(1);
}

section.blog .card img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

section.blog .card-container {
  display: flex;
  justify-content: space-between;
}

section.blog .card {
  flex: 1;
  border: 1px solid #ccc;
  text-align: center;
  transition: box-shadow 0.3s, transform 0.3s;
  padding: 10px !important;
}

section.blog .card .image,
section.blog .card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 20px;
}

section.blog .card h6 {
  font-weight: 600;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogtext p {
  text-align: left !important;
  font-size: 14px !important;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogtext_container {
  padding: 0 10px;
}

.blog_section h4 {
  font-weight: 600;
  letter-spacing: 1px;
  margin: 2rem 0 3rem 0;
  text-align: center;
  width: 70%;
}

.blog_section .learnmore {
  background-color: #ffe81a;
  border-radius: 8px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.5);
  padding: 5px;
}

@media screen and (max-width: 991px) {
  section.blog {
    margin: 5rem 0 5rem 0;
  }
}

@media screen and (max-width: 768px) {
  .blogtext p {
    text-align: left !important;
    font-size: 14px !important;
  }

  section.blog {
    margin: 4rem 0 4rem 0;
  }

  .blog_section h4 {
    margin: 2rem 0 3rem 0;
    font-size: 20px !important;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .blog_section h4 {
    margin: 2rem 0 3rem 0;
    font-size: 18px !important;
  }
}

/* -------------------------------------------------------------------------------------------------------------------------- */

.langScreen .langText {
  margin-bottom: 2rem;
}

.langScreen .langText p {
  font-weight: 500;
}

.langScreen .langText h6 {
  font-weight: 700;
}

.lang_btn_divs {
  display: flex;
  gap: 1rem;
}

.lang_btn_divs button {
  display: inline-flex;
  border: double 2px transparent;
  border-radius: 80px;
  background-image: linear-gradient(#ffffff, #ffffff),
    radial-gradient(
      circle at top left,
      #f48908 3.01%,
      #f51929 20.37%,
      #9713b9 51.36%,
      #b620a9 69.35%,
      #7602bd 97.54%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 100%;
  height: 38px;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background-color: #54c0eb;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s ease-in;
}

.lang_btn_divs button:hover {
  background-image: linear-gradient(#f7e8fc, #f7e8fc),
    radial-gradient(
      circle at top left,
      #f48908 3.01%,
      #f51929 20.37%,
      #9713b9 51.36%,
      #b620a9 69.35%,
      #7602bd 97.54%
    );
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

/* -------------------------------------------------------------------------------------------------------------------------- */

.outer-div {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  height: 100%;
  border-radius: 30px;
  padding: 3rem 2rem 4rem 2rem;
}

.inner-div {
  text-align: left;
  font-family: Poppins;
  font-size: 28px;
}

.inner-div h4 {
  margin-bottom: 1.3rem !important;
}

.inputBtnDiv {
  position: relative;
}

.newsltrHeading {
  font-weight: 500;
  line-height: 25px;
  color: rgba(255, 255, 255, 1);
}

.newsltrtext {
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 1.5rem;
}

.emailInpt {
  border-radius: 70px !important;
  font-size: 15px;
  padding: 0.8rem 1rem;
  border: none;
  width: 100%;
  font-weight: 500;
  outline: none;
}

.Subscribebtn {
  background: linear-gradient(270.13deg, #c316df 0.44%, #d05f1f 77.74%);
  border: none;
  border-radius: 30px;
  font-size: 14px;
  padding: 0.3rem 1rem;
  position: absolute;
  right: 11px;
  top: 11px;
  color: #ffffff !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s ease-in;
}

.Subscribebtn:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .emailInpt {
    font-size: 15px;
    padding: 0.7rem 1rem;
  }

  .Subscribebtn {
    font-size: 13px;
    padding: 0.3rem 0.8rem;
  }
}

@media screen and (max-width: 576px) {
  .Subscribebtn {
    font-size: 12px;
    padding: 0.3rem 0.8rem;
    gap: 0.3rem;
  }

  .outer-div {
    padding: 3rem 1rem 4rem 1rem;
  }
}

/* -------------------------------------------------------------------------------------------------------------------------- */

.footer_container {
  padding: 3rem 1rem 4rem 1rem;
}

.footer_container ul {
  list-style: none;
  padding: 0;
}

.footer_container ul a {
  text-decoration: none;
  color: #000;
}

.single-footer-widget h6 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.footer_container ul li {
  font-size: 15px;
  font-weight: 500;
  margin: 0.7rem 0 !important;
}

.footer_container .img3 {
  margin-bottom: 2rem;
}

.cntct {
  margin: 0.7rem 0;
}

.cntct p {
  font-size: 15px;
  font-weight: 500;
}

.cntct_text {
  display: flex;
  align-items: flex-start;
  gap: 0.6rem;
}

.cntct_text p {
  margin: 0;
}

.cntct_text img {
  width: 1.3rem;
}

.horixon_footer {
  border-radius: 20px;
  height: 5px;
  width: 100%;
  background: linear-gradient(
    270deg,
    #8f4699 0.73%,
    #cf6269 20.89%,
    #15ff3e 38.47%,
    #17ffff 58.12%,
    #1700f5 78.8%,
    #ec00e7 100%
  );
  margin-top: 4rem;
}

@media screen and (max-width: 991px) {
  .cntct_div {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .mediaLinks {
    text-align: center;
    margin-top: 1.5rem;
  }

  .mediaLinks ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mediaLinks ul li {
    margin: 0 16px !important;
    margin-bottom: 1rem !important;
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */

.welcm_video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  padding: 0;
  position: relative;
}

.closevideobtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.closevideobtn:hover {
  transform: scale(1.1);
}

.welcm_video video {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.sideIcons {
  width: fit-content;
  height: fit-content;
  position: fixed;
  top: 40%;
  left: 1rem;
  z-index: 99999999999999999;
}

.sideIcons ul {
  list-style: none;
  padding: 0;
}

.sideIcons ul li {
  list-style: none;
  padding: 0;
  margin-bottom: 0.6rem;
  transition: 0.2s;
  cursor: pointer;
}

.sideIcons ul li:hover {
  transform: scale(1.1);
}

.sideIcons1 {
  border-radius: 50%;
  box-shadow: -4.588235378265381px 0px 11.470587730407715px 0px
    rgba(16, 176, 26, 0.56);
}

.sideIcons2 {
  border-radius: 50%;
  box-shadow: -4.588235378265381px 0px 11.470587730407715px 0px
    rgba(200, 55, 171, 0.53);
}

.sideIcons3 {
  border-radius: 50%;
  box-shadow: -4.588235378265381px 0px 11.470587730407715px 0px
    rgba(117, 210, 255, 1);
}

.sideIcons4 {
  border-radius: 50%;
  box-shadow: -4.588235378265381px 0px 11.470587730407715px 0px
    rgba(255, 175, 172, 1);
}

.seeAll {
  width: 100%;
  text-align: right;
  margin: 1rem 0;
}

.seeAllBtn {
  border: none;
  font-weight: 500;
  font-size: 16px;
  padding: 0.2rem 1rem;
  transition: all 0.2s ease-in;
}

.seeAllBtn:hover {
  text-decoration: underline;
}

/* ------------------------------------------------------------------------------------------------------------------------- */

.wlcmContentRow {
  margin: 4rem auto;
  position: relative;
}

.wlcmContentRow .wlcm_left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome_screen_container .wlcm_right {
  margin-right: 0 !important;
}

.welcome_screen_container .wlcm_right .mainLandImg {
  width: 100%;
  height: 570px;
}

.welcome_screen_container .wlcm_right img {
  width: 100%;
  height: 100%;
}

.wlcmContentRow .wlcm_left .wlcmText P {
  color: rgba(126, 50, 203, 1);
  font-weight: 500;
  font-size: 20px;
}

.wlcmContentRow .wlcm_left .wlcmText h3 {
  font-weight: 700 !important;
  margin-bottom: 1rem;
}

.getInBtn button {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  border: none;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.5rem 1rem;

  transition: 0.2s all ease-in;
  margin-top: 25px;
}

.getInBtn button:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.wlcmCnt {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 14px;
}

@media screen and (max-width: 1400px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 530px;
  }
}

@media screen and (max-width: 991px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 380px;
  }

  .main_welcome_screen {
    height: 60vh !important;
  }
}

@media screen and (max-width: 850px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 340px;
  }
}

@media screen and (max-width: 768px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 300px;
  }

  .wlcmContentRow .wlcm_left .wlcmText P {
    color: rgba(126, 50, 203, 1);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }

  .wlcmContentRow .wlcm_left .wlcmText h3 {
    font-weight: 700 !important;
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .getInBtn button {
    background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
    border: none;
    border-radius: 30px;
    color: #ffffff;
    font-weight: 500;
    padding: 0.5rem 1rem;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 740px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 295px;
  }
}

@media screen and (max-width: 650px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .wlcmContentRow {
    flex-direction: column-reverse;
  }

  .welcome_screen_container .wlcm_right {
    margin-right: auto !important;
  }

  .wlcmContentRow .wlcm_left {
    margin: 1rem auto 2rem auto;
  }
}

@media screen and (max-width: 380px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 250px;
  }

  .wlcmContentRow .wlcm_left .wlcmText P {
    color: rgba(126, 50, 203, 1);
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 0.5rem;
  }

  .wlcmContentRow .wlcm_left .wlcmText h3 {
    font-weight: 700 !important;
    margin-bottom: 1rem;
    font-size: 17px;
    color: #000;
  }

  .getInBtn button {
    background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
    border: none;
    border-radius: 30px;
    color: #ffffff;
    font-weight: 500;
    padding: 0.5rem 1rem;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 330px) {
  .welcome_screen_container .wlcm_right .mainLandImg {
    width: 100%;
    height: 200px;
  }
}

.soluLeft img {
  width: 100%;
}

.soluText p {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

.soluText h3 {
  font-weight: 700;
  color: #ffffff;
}

.soluText h6 {
  font-weight: 500;
  letter-spacing: 1px;
  margin: 1rem 0;
  color: #ffffff;
  line-height: 1.5rem;
}

.getInBtnWhite button {
  color: #000;
  border: none;
  font-weight: 500;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  font-size: 15px;
  transition: 0.2s all ease-in;
}

.getInBtnWhite button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 991px) {
  .soluText h6 {
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 1rem 0;
    color: #ffffff;
    line-height: 1.1rem;
  }

  .soluText h3 {
    font-size: 22px !important;
    font-weight: 700;
    color: #ffffff;
  }

  .soluText p {
    font-size: 17px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.4rem;
  }
}

@media screen and (max-width: 768px) {
  .soluLeft {
    margin-bottom: 1rem !important;
  }

  .soluText h3 {
    font-size: 17px !important;
    font-weight: 700;
    color: #ffffff;
  }

  .soluText p {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.4rem;
  }
}

.soluCard {
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem !important;
  height: 100%;
}

.soluCardHeaD {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.soluCardHeaD img {
  width: 2.5rem;
}

.soluCardHeaD p {
  margin: 0;
  font-weight: 500;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.soluCardBody p {
  font-size: 15px;
  line-height: 1.1rem;
  margin: 0;
}

.solution2 {
  margin: 3rem auto;
}

@media screen and (max-width: 991px) {
  .soluCardBody p {
    font-size: 14px;
    line-height: 1.1rem;
    /* margin-bottom: 1rem; */
  }
}

.productCard1 {
  background: linear-gradient(91.62deg, #ea7b5b 14.86%, #be2f25 115.47%);
}

.productCard2 {
  background: rgba(175, 211, 113, 1);
}

.productCard1,
.productCard2 {
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  height: 100%;
}

.productCard1 h4,
.productCard2 h4 {
  font-weight: 600;
  margin-bottom: 1.1rem;
}

.productCard1 p,
.productCard2 p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 1.3rem;
}

.learnPrdctBtn button {
  border: none;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  transition: 0.2s all ease-in;
}

.learnPrdctBtn button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.product_container {
  margin: 4rem auto;
}

.pcardH {
  height: 100% !important;
  margin-bottom: 1rem !important;
}

.companyText p {
  color: rgba(102, 102, 102, 1) !important;
}

.companyAboutImg {
  margin-bottom: 1.5rem;
}

.companyAboutImg img {
  width: 100%;
}

.companyAboutImg h3 {
  font-weight: 600;
}

.companyAboutImg p {
  color: rgba(102, 102, 102, 1);
  margin: 1rem 0;
  line-height: 1.8rem;
}

.missionDiv {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  padding: 3rem 2rem;
  border-radius: 25px;
  color: #ffffff;
}

.vissionContainer h6 {
  font-weight: 600;
}

.vissionContainer p {
  font-size: 15px;
}

.vissionGoal {
  margin-bottom: 1.3rem !important;
}

.vissionGoal {
  display: flex;
  align-items: center;
}

.coreTeamHead {
  display: flex;
  justify-content: center;
  margin: 4rem auto 3rem auto;
}

.coreTeamHead h4 {
  width: fit-content;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  font-weight: 600;
}

.coreTeamCardImg img {
  width: 100%;
}

.coreTeamCardText {
  text-align: center;
}

.coreTeamCardText h6 {
  color: (51, 51, 51, 1);
  font-weight: 600;
  margin: 1rem auto;
  margin-top: 2rem !important;
}

.coreTeamCardDetails p {
  margin: 0;
  font-size: 15px;
  color: (51, 51, 51, 1);
}

.coreTeamCard {
  margin-bottom: 2rem;
}

section.caseStudies {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  padding: 2rem;
}

.certiImg {
  padding: 2rem;
}

.certiImg img {
  width: 100%;
}

.caseStudiesHead {
  margin: 1rem 0;
}

.caseStudiesHead p {
  color: #ffffff;
  font-size: 13px;
  margin: 0;
  font-weight: 500;
}

.caseStudiesHead h5 {
  color: #ffffff;
  margin: 0;
  font-weight: 600;
}

.certificate img {
  width: 100%;
}

.cartificateSection {
  position: relative;
}

.cartificateContainer {
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
}

.emptyHeight {
  height: 250px;
  width: 100%;
}

.presenceCountriesimage {
  padding: 2rem;
}

.presenceCountriesimage img {
  width: 100%;
}

.presenceCountriesText {
  text-align: center;
}

.presenceCountriesText {
  text-align: center;
  margin: 1rem 0;
}

.presenceCountriesText p {
  margin: 0;
  font-size: 14px;
}

.presenceCountriesimage {
  margin-bottom: 2rem;
}

.presenceHead {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem auto 2rem auto;
}

.presenceHead h4 {
  width: fit-content;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  font-weight: 600;
}

.Presence_bar_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.filterForm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.filterForm label {
  font-size: 15px;
  display: flex;
  align-items: center;
}

.filterForm input {
  margin-left: 0.5rem;
}

.Presence_bar {
  border: 3px solid rgba(0, 0, 0, 1);
  padding: 1rem;
  border-radius: 30px;
  margin-bottom: 3rem;
}

.Presence_bar_div h5,
.Presence_bar_div h6 {
  margin: 0;
}

.enevtsImg {
  width: 100%;
}

.eventsHead h1 {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  font-weight: 700;
  width: fit-content;
}

.eventsHead p {
  color: rgba(48, 48, 48, 1);
}

.events_container {
  position: relative;
}

.eventsHead {
  position: absolute;
  top: 10%;
}

section.events {
  margin: 10rem 0;
}

.enevtsImg img {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .eventsHead {
    position: absolute;
    top: 3%;
  }

  .emptyHeight {
    height: 200px !important;
  }
}

@media screen and (max-width: 991px) {
  .emptyHeight {
    height: 150px !important;
  }

  .companyAboutImg p {
    color: rgba(102, 102, 102, 1);
    margin: 1rem 0;
    line-height: 1.5rem;
    font-size: 14px;
  }

  .presenceCountriesimage {
    padding: 1rem !important;
  }
}

@media screen and (max-width: 768px) {
  .eventsHead {
    position: absolute;
    top: 7%;
  }

  .Presence_bar_div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 0.5rem;
  }

  .eventsHead p {
    font-size: 13px;
  }

  .presenceCountriesText p {
    font-size: 12px;
  }

  section.events {
    margin: 5rem 0 !important;
  }

  .certiImg {
    padding: 1rem !important;
  }

  .presenceCountriesimage {
    padding: 0.5rem !important;
  }

  .RogerCard p {
    font-size: 13px !important;
  }

  .RogerCard {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 630px) {
  .eventsHead {
    position: absolute;
    top: 4%;
  }

  .filterForm {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .filterForm label {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  .eventsHead {
    position: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem;
  }

  .product_container {
    margin: 4rem 0;
  }

  .product_container .col-md-5 {
    margin-bottom: 20px;
  }

  .certiImg {
    padding: 0.5rem !important;
  }

  .cartificateContainer {
    width: 100%;
    position: absolute;
    top: 30;
    transform: translateY(-30%);
  }

  .emptyHeight {
    height: 100px !important;
  }

  .filterForm label {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .emptyHeight {
    height: 60px !important;
  }
}

.JoinOurTeamHead {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.join_text {
  opacity: 0.5;
}

.joinNow_btn {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  border: none;
  border-radius: 30px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.5rem 1rem;

  transition: 0.2s all ease-in;
  margin-top: 25px;
}

.joinNow_btn:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.JoinOurTeam {
  margin-bottom: 3rem;
}

.chall_class {
  text-align: center;
}

.hiddenTextP {
  display: none;
}

.redmorbtn button {
  border: none;
  font-weight: 500;
  text-decoration: underline;
  background: transparent !important;
  outline: none;
}

.redmorbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.RogerCard {
  display: flex;
  /* align-items: center; */
  gap: 1rem;
}

.RogerCardText {
  text-align: left !important;
}


.font_size_popup{
  font-size: 15px;
  line-height: 0.1;
  letter-spacing: 0px;
}

.dropdownLinkNAv {
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}