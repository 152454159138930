.qrpage {
  background-color: #f2f2f2 !important;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #373435;
}

.qrpage2 {
  background-color: #f2f2f2 !important;
  height: 100vh;
  width: 100vw;
  color: #373435;
}

.qr_wrapper {
  border-radius: 7px;
  border: 3px solid #ffffff;
}

.scanQR img {
  /* width: 200px; */
  width: 100%;
}

.qrsrceen {
  background-color: #ededee;
  border: 7px solid #e6e7e8;
  border-radius: 7px;
  padding: 4rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.qrsrceen2 {
  padding: 2rem 2rem !important;
}

.qr_contaianer {
  padding: 1rem;
}

.qrHead {
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrHead h5 {
  font-weight: 600;
}

.comapnyLogo {
  margin: 1.5rem auto;
  text-align: center;
}

.comapnyLogo p {
  margin-top: 0.7rem;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}

.scanQR {
  text-align: center;
}

.scanQR h4 {
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (min-width: 991px) {
  .qrsrceen2 {
    padding: 2rem 2rem !important;
  }
}

@media screen and (max-width: 991px) {
  .qr_wrapper {
    height: 100%;
  }

  .qr_contaianer {
    height: 100%;
  }

  .qrsrceen {
    padding: 8rem 2rem;
  }

  .qrHead h5 {
    font-weight: 600;
    font-size: 28px;
  }

  .scanQR {
    margin-top: 2rem;
  }

  .scanQR h4 {
    font-size: 30px;
    margin-bottom: 2rem;
  }

  .comapnyLogo p {
    margin-top: 0.7rem;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 768px) {
  .qr_wrapper {
    height: 100%;
  }

  .qr_contaianer {
    height: 100%;
  }

  .qrsrceen {
    padding: 4rem 1rem;
  }

  .qrHead h5 {
    font-weight: 600;
    font-size: 22px;
  }

  .scanQR {
    margin-top: 2rem;
  }

  .scanQR h4 {
    font-size: 24px;
    margin-bottom: 2rem;
  }

  .comapnyLogo p {
    margin-top: 0.7rem;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 576px) {
  .qr_wrapper {
    height: 100%;
  }

  .qr_contaianer {
    height: 100%;
    padding: 0.3rem;
  }

  .qrsrceen {
    padding: 4rem 1rem;
    justify-content: flex-start !important;
  }

  .qrHead h5 {
    font-weight: 600;
    font-size: 18px;
  }

  .scanQR {
    margin-top: 2rem;
  }

  .scanQR h4 {
    font-size: 18px;
    margin-bottom: 2rem;
  }

  .comapnyLogo p {
    margin-top: 0.7rem;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
    background-clip: text;
    color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 100% 100%;
  }
}

.comapnyLogo1 img {
  width: 100%;
}

.fillInfoHead h4 {
  font-weight: 500;
  font-size: 20px;
}

.fillInfoHead {
  margin: 4rem auto 2rem auto;
  text-align: center;
}

.fillInfoHeadImg {
  margin-top: 1rem;
}

.fillInfoHeadImg img {
  width: 4rem;
}

.QRInput {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border: 2px solid rgba(102, 102, 102, 1);
  padding: 0.5rem 1rem;
  border-radius: 30px;
  margin: 1rem 0;
}

.QRInput img {
  width: 1.3rem;
}

.QRInput select,
.QRInput input {
  border: none;
  height: 30px;
  background-color: transparent;
  padding-left: 1rem;
  outline: none;
  border-left: 2px solid rgba(102, 102, 102, 1);
  width: 100%;
}

.QRInput select {
  width: 100% !important;
}

.SaveBtn {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.SaveBtn button {
  border: none;
  outline: none;
  background-color: #8f4699;
  color: #ffffff;
  font-weight: 500;
  padding: 0.4rem 1.5rem;
  border-radius: 30px;
}

.SaveBtn button img {
  width: 1.4rem !important;
}

.react-international-phone-country-selector-button {
  background-color: transparent !important;
  border: none !important;
  padding-right: 8px !important;
}

.react-international-phone-country-selector-button__flag-emoji {
  margin: 0 !important;
  margin-right: 5px !important;
}

.react-international-phone-input-container .react-international-phone-input {
  background-color: transparent !important;
  width: 100% !important;
  border: none !important;
  border-left: 2px solid rgba(102, 102, 102, 1) !important;
}

.react-international-phone-input-container {
  width: 100%;
}

.scanQR2 {
  margin-top: 2.5rem;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column;
  gap: 0.5rem;
}

.scanQR2 h4 {
  font-size: 17px;
}

.timer30s p {
  font-weight: 600;
  color: #781fc4;
}

.tokenNumber {
  margin-top: 2rem;
  text-align: center;
}

.tokenNumber h4 {
  background: linear-gradient(91.62deg, #7e32cb 14.86%, #be2f25 115.47%);
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
  font-weight: 600;
}

.QRInputFAQ {
  border: none;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.QRInputFAQ label {
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.QRInputFAQ input {
  border: 2px solid rgba(102, 102, 102, 1) !important;
  border-radius: 7px;
  padding: 1rem;
}

.QRInputFAQ textarea {
  border: 2px solid rgba(102, 102, 102, 1) !important;
  border-radius: 7px;
  padding: 0.5rem;
  width: 100%;
}

.fillInfoHead2 {
  margin: 2.5rem auto 0 auto !important;
}

.QRInput2 input {
  border: none;
  height: 30px;
  background-color: transparent;
  padding-left: 1rem;
  outline: none;
  border-left: 2px solid rgba(102, 102, 102, 1);
}
