

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}

.error-content {
    text-align: center;
    color: white;
    z-index: 1;
}

.error-content h1 {
    font-size: 10rem;
    margin: 0;
}

.error-content p {
    font-size: 1.5rem;
    margin-top: 10px;
}

.error-content a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.error-content a:hover {
    background-color: #0056b3;
}

.animated-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;    
    height: 100%;
    background: linear-gradient(-45deg, #007BFF, #F9BACF, #0056b3, #F9BACF);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
    