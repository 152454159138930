.Input_section {
  margin-top: 50px;
  justify-content: flex-end;
}

.rsrcimage {
  margin-right: 0;
  margin-left: auto;
}

.rsrcimage img {
  width: 100%;
  height: auto;
}

.resources_container {
  padding-top: 60px;
  position: relative;
}

.rsrc_text {
  position: absolute;
  top: 6rem;
  left: 5rem;
  width: 50%;
}

.rsrc_text h1 {
  font-size: 60px !important;
  font-weight: 600;
}

.rsrc_text h5 {
  font-size: 17px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 80%;
}

.rsrc_text button {
  border: none;
  text-decoration: underline;
  font-weight: 500;
  background-color: transparent;
}

@media screen and (max-width: 991px) {
  .rsrc_text h1 {
    font-size: 40px !important;
    font-weight: 600;
  }

  .rsrc_text h5 {
    font-size: 15px !important;
    margin-top: 0.8rem;
    margin-bottom: 1.3rem;
    width: 100%;
  }

  .rsrc_text {
    position: absolute;
    top: 5rem;
    left: 5rem;
    width: 50%;
  }

  .resources_container {
    padding-top: 45px;
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .rsrc_text h1 {
    font-size: 30px !important;
    font-weight: 600;
  }

  .rsrc_text h5 {
    font-size: 14px !important;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .rsrc_text {
    position: absolute;
    top: 3rem;
    left: 4rem;
    width: 50%;
  }

  .resources_container {
    padding-top: 30px;
    position: relative;
  }
}

@media screen and (max-width: 576px) {
  .rsrc_text h1 {
    font-size: 24px !important;
    font-weight: 600;
  }

  .rsrc_text h5 {
    font-size: 12px !important;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .rsrc_text {
    position: absolute;
    top: 3rem;
    left: 1rem;
    width: 50%;
  }

  .resources_container {
    padding-top: 30px;
    position: relative;
  }

  .rsrc_text button {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .rsrc_text h1 {
    font-size: 22px !important;
    font-weight: 600;
  }

  .rsrc_text h5 {
    font-size: 12px !important;
    margin-top: 0.4rem;
    margin-bottom: 0.7rem;
    width: 100%;
  }

  .rsrc_text {
    position: absolute;
    top: 2rem;
    left: 1rem;
    width: 50%;
  }

  .resources_container {
    padding-top: 10px;
    position: relative;
  }

  .rsrc_text button {
    font-size: 14px;
  }
}

/* ------------------------------------------------------------------------------------------------------------------------ */

.Input_section .inputCheck1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2rem;
}

.inputBlogHead {
  border-right: 4px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputBlogHead h2 {
  font-size: 50px;
  font-weight: 600;
}

.inputHead {
  margin-bottom: 0.5rem;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row !important;
  gap: 1.2rem;
  margin-bottom: 0.4rem;
  padding: 0;
  font-size: 15px;
}

.form-check-label {
  margin-right: 10px;
  font-weight: 500;
  margin-bottom: -6px;
}

.form-check-input:checked {
  background-color: rgba(60, 60, 60, 1);
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.form-check-input {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.inputHead p {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.blog2_section {
    margin-bottom: 5rem;
}

@media screen and (max-width: 991px) {
    .form-check {
        gap: 0.7rem;
        font-size: 15px;
      }

      .inputBlogHead {
        border-right: 4px solid #000;
        margin-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .form-check {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        gap: 1rem;
        margin-bottom: 0.4rem;
        padding: 0;
        font-size: 13px;
      }

      .Input_section .inputCheck1 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem !important; 
      }

      .inputBlogHead h2 {
        font-size: 40px !important;
        font-weight: 600;
      }
}

@media screen and (max-width: 775px) {
    .form-check {
        gap: 0.7rem;
        font-size: 15px;
      }

      .inputBlogHead {
        border-right: none !important;
        border-bottom: 2px solid #000;
        margin-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
      }

      .form-check {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        gap: 1rem;
        margin-bottom: 0.4rem;
        padding: 0;
        font-size: 13px;
      }

      .Input_section .inputCheck1 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 0.5rem !important; 
      }

      .inputBlogHead h2 {
        font-size: 40px !important;
        font-weight: 600;
      }
}

@media screen and (max-width: 576px) {
    .Input_section .inputCheck1 {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0rem !important; 
      }
}

/* -------------------------------------------------------------------------------------------------------------------------- */

.shoblog_container {
  margin: 4rem auto;
}

.shoblogHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blogprofileinfo {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.blogprofileinfoText h5 {
  color: rgba(126, 50, 203, 1);
  margin-bottom: 0.1rem;
} 

.blogprofileinfoText p {
  font-size: 13px;
  margin: 0;
  color: rgba(109, 110, 118, 1);
} 

.blogsocialicons {
  display: flex;
  gap: 0.5rem;
}

.blogsocialicons img {
  width: 2rem;
}

.blogheading1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.blogheading1 ul{
  padding-left: 1rem;
}

.Robo_content {
  margin: 3rem auto 4rem auto !important;
}

.Robo_content p {
  font-size: 15px;
  line-height: 1.5rem;
}

.Robo_content .blogImg2 {
  margin: 2rem auto 3rem auto;
} 

.blogImg11 img,
.Robo_content .blogImg2 img {
  width: 100%;
}

.blogupfooter {
  text-align: center;
}

.blogupfooter h3 {
  font-weight: 500 !important;
}

.whatsnexttoreadcard {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.whatsnexttoreadcard:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) !important;
}

.post_dateTimes {
  text-align: left;
  padding-left: 10px;
  margin-bottom: 0.2rem;
}

.post_dateTimes p {
  margin: 0;
  color: #4C4C4C;
}

.post_dateTimes span {
  color: #592EA9;
}

.blogSHowSEction {
  margin-top: 2rem !important;
}

.blogprofileinfo img {
  width: 3rem;
}