.AmraConversations_data {
  height: 100%;
  width: 100%;
}

.AmraConversations {
  height: 100%;
}

.loadeTechnoLoaderNew {
  border-radius: 30px !important;
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999999999999999;
}

.noChats_selected {
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatHistry_container ul {
  list-style: none;
  padding: 0;
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 0.6rem;
  margin-bottom: 0;
}

.chatHistry_container li {
  margin-bottom: 0.7rem;
}

.chatHistry_container {
  margin-top: 2rem;
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5rem 1rem;
  padding-right: 0.1rem;
}

::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Round the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #eee; /* Set the color of the scrollbar track */
  border-radius: 6px;
}

.chatHistry_container .chatHistryBox {
  border: 1px solid #707070;
  border-radius: 20px;
  padding: 1.5rem 1rem;
  background-color: #eef1f3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: scale(0.98);
}

.chatHistry_container .chatHistryBox:hover {
  transform: scale(1);
}

.chatHistryBoxHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatHistryBoxHead p {
  font-size: 13px;
  margin: 0;
}

.sleectdChatHistryChat_container {
  margin-top: 2rem;
  background-color: #ffffff;
  border-radius: 20px;
  width: 100%;
  padding: 1.5rem 1rem;
  padding-right: 0.1rem;
}

.sleectdChatHistryChat_msgs {
  height: 500px;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 0.6rem;
}

.amramsg {
  background-color: #eef1f3;
  border: 1px solid #707070;
  width: fit-content;
  max-width: 85%;
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
  margin: 0.8rem auto 0.6rem 0;
}

.amramsg p {
  margin: 0;
}

.usermsg {
  background-color: #a537d8;
  border: 1px solid #707070;
  color: #ffffff;
  width: fit-content;
  max-width: 85%;
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
  margin: 0.8rem 0 0.6rem auto;
}

.usermsg p {
  margin: 0;
}

.nosleectdChatHistryChat {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  max-height: 500px;
}

.Dashpages_contaioner {
  height: 100%;
  overflow: auto; /* Make this container scrollable */
}

.ovrvwCard {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 15px;
  margin-bottom: 0.6rem;
}

.chatHistryBoxHead h6 {
  opacity: 0 !important;
}
