.seeMsgBtn {
  background-color: rgba(31, 100, 255, 1);
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-size: 13px;
}

.theadStyle th {
  background-color: rgba(78, 78, 78, 1) !important;
  color: #ffffff;
  font-weight: 600;
  padding: 1rem;
}

.theadStyle thead {
  border-radius: 20px;
}

.tboday .th1,
.theadStyle .th1 {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}

.tboday .th2,
.theadStyle .th2 {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.tboday tr {
  border-top: 8px solid #f7e8fc;
  border-bottom: 8px solid #f7e8fc;
}

.tboday tr td {
  background-color: #ffffff;
  padding: 0.8rem 1rem;
}

.BookdataTable {
  width: 100%;
}

.contactDetailsDiv p span {
  color: rgb(90, 90, 90);
  font-weight: 600;
}

.BookdataTable {
  margin: 2rem 0 0 0;
}

.modalCloseBtn23 {
  border: none;
  background-color: transparent;
  font-size: 1.8rem;
}

.modalHeader23 {
  padding: 0 0.7rem !important;
  padding-top: 0.1rem !important;
}

.BookdataTable {
  width: 100%;
  border-collapse: collapse;
}

.theadStyle {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust the background color as needed */
  z-index: 1;
}

.tboday {
  overflow-y: auto;
  max-height: 400px; /* Adjust the max height as needed */
}
