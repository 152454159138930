#sidebar {
  min-width: 270px;
  max-width: 270px;
  background: #f8f9fa;
  transition: all 0.3s;
  height: 100vh;
}

.sidebar_container {
  position: sticky;
  top: 0;
}

.sidebar {
  position: sticky;
  top: 0;
}

#sidebar.active {
  margin-left: -270px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.new_chat_btn {
  height: 72px;
}

.new_chat_btn img {
  width: 100%;
  height: 100%;
}

.mainMenuItem img {
  width: 1.4rem;
}

.mainMenuContainer ul {
  list-style: none;
  padding: 0;
}

.mainMenuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  cursor: pointer;
}

.mainMenuContainer1 ul li {
  padding: 0.8rem 1rem;
}

.mainMenuItem p {
  font-weight: 500;
  color: rgba(52, 58, 64, 1);
  font-size: 14px;
  margin: 0;
}

.sidebar-body-container {
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar-body-container h6 {
  color: rgba(134, 142, 150, 1);
  font-size: 12px;
}

.upgradePlanContianer {
  text-align: center;
  background-color: rgba(255, 249, 219, 1) !important;
  padding: 1rem 0;
  margin-top: 1.5rem;
  position: relative;
}

.closeImgDiv {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.closeImgDiv img {
  width: 0.7rem;
}

.upgradePlanContianer .upgradePlanHead {
  color: rgba(252, 145, 43, 1);
  font-size: 15px !important;
  font-weight: 500;
}

.upgradePlanContianer p {
  color: rgba(52, 58, 64, 1);
  font-size: 11px;
}

.upgradePlanContianer button {
  background-color: rgba(52, 58, 64, 1);
  color: #ffffff;
  font-weight: 500;
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 6px;
}

.new_chat_btn1 {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
}

.new_chat_btn1 img {
  width: 170px;
}

.sideBarHead {
  justify-content: space-between !important;
}

.activemainMenuItem {
  color: #ffffff !important;
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  border-radius: 7px;
}

.activemainMenuItem p {
  color: #ffffff !important;
}

@media screen and (max-width: 1024px) {
  #sidebar {
    margin-left: -270px;
  }

  #sidebar.active {
    margin-left: 0 !important;
  }
}

.btn_toggle1 img {
  width: 1.8rem;
  transition: all 0.2s ease-in-out;
}

.btn_toggle1ani img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.new_chat_btn2 {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80px !important;
}

.new_chat_btn2 img {
  width: 170px;
}

.sideBarHead2 {
  flex-direction: column;
  padding: 0 1rem;
}
