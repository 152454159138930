.formPath {
  background-color: white;
}
.round12 {
  border: 2px solid black;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.round12 p {
  margin: 0 !important;
}

.round12cmpltd {
  background-color: #7e32cb;
}

.round12cmpltd p {
  color: #ffffff !important;
}

.uploadBtn {
  background-color: white;
  border-radius: 15px;
  color: rgba(52, 58, 64, 1);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadBtn:hover {
  transform: scale(1.1);
}
.formInput .addbtn {
  background-color: green;
  display: flex;
  gap: 0.5rem;
  border: none;
  outline: none;
  padding: 0.3rem 1.5rem;
}

.formInput h5 {
  margin: 0;
}

.formInput .addbtn img {
  width: 1.3rem;
}

.formInput .addbtn p {
  margin: 0;
}

.inputs {
  height: 100%;
}

.formInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;
  margin-bottom: 1.5rem;
}

.formInput label {
  white-space: nowrap;
  margin: 0;
}

.horizonline {
  width: 20%;
  background-color: black;
  height: 2px;
}

.userBasicInfo {
  margin-top: 2rem;
}

.userBasicInfo h4 {
  margin-bottom: 1rem;
}

.uploadPhoto {
  height: 100%;
  width: 100%;
}

.form-row {
  padding-left: 12px;
}

.formInput2 textarea {
  width: 100%;
}

.formInput2 {
  width: 100%;
  flex-direction: column !important;
  align-items: flex-start !important;
}
