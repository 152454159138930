.chartInteraction {
  height: auto;
  width: auto;
  background-color: red;
}

.inContent .imgLayer img {
  height: 3rem;
  width: 3rem !important;
  margin: 1rem 10px;
}

.valueCardCallview .caRDS {
  align-items: center;
  width: fit-content !important;
}

.valueCardCallview .caRDS .inContent {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.lineChartCOl13 {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.vertical_center {
  place-self: center;
}

.debtordetailCards {
  margin-left: 10px;
}

.radialChartBody {
  background-color: #ffffff;
  border-radius: 5%;
  padding: 15px;
}

.avgRspTm {
  justify-content: space-between;
  align-items: center;
}

.grp4 {
  cursor: pointer;
}

.ouTChart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.d-flex-overflow {
  overflow: auto;
}

.Purple-point {
  width: 12px;
  height: 12px;
  background-color: rgba(79, 63, 240, 1);
  border-radius: 50%;
}

.Pink-point {
  width: 12px;
  height: 12px;
  background-color: rgba(223, 54, 112, 1);
  border-radius: 50%;
}

.User_Feedback {
  margin: 0px;
}

.maleUser {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.maleUser img {
  width: 1.8rem;
  height: 1.8rem;
}

.height_100_per {
  height: 100%;
}
.debtordetailCardNafia {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  display: none !important;
}

.percnTAGE {
  display: flex;
  gap: 0.5rem;
}

.percnTAGE p {
  font-size: 14px;
  margin: 0;
}

.pointdiv {
  padding-top: 6px;
}

.ARTtxtContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
}

.ARTtxt {
  align-items: center;
}

.tableData12 p {
  color: #000 !important;
  font-weight: 500 !important;
}

.downloadCRmDIv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting_asc_disabled,
table.dataTable thead > tr > th.sorting_desc_disabled,
table.dataTable thead > tr > td.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting_asc_disabled,
table.dataTable thead > tr > td.sorting_desc_disabled {
  padding-right: 0 !important;
}

.nafiaSuperOverview
  .amradataTable_container
  table.dataTable
  thead
  > tr
  > th.sorting {
  text-align: center;
}

.tableData12 {
  justify-content: center;
}

.dataTables_wrapper .dataTables_length select {
  border-radius: 10px !important;
  font-size: 14px;
  height: 30px;
  font-weight: 500;
}

.dataTables_wrapper .dataTables_filter input {
  border-radius: 10px;
  height: 35px;
  width: 200px;
}

.dataTables_info {
  display: none;
}

.dataTables_wrapper .dataTables_paginate {
  float: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  border-radius: 10px;
  border: 1px solid #c5c5d3 !important ;
  font-size: 14px !important;
  cursor: pointer !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: none !important;
  background-color: #e5f0ff !important;
  color: #006aff !important;
}

.amradataTable_container2 {
  background-color: none !important;
  border-radius: 0 !important;
}

.datatableCOntainerDiv {
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 1rem;
  margin-left: 12px;
  margin-right: 12px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.datatableCOntainerHead {
  padding: 12px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
}

.datatableCOntainerHead .filterBtnDivs button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #a8a8bd;
  background-color: transparent !important;
  border: 1px solid #c5c5d3;
  border-radius: 12px;
  padding: 0.3rem 0.5rem;
}

.datatableCOntainerHead .filterBtnDivs button p {
  margin: 0;
}

.datePickeriv {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.datePickerivSelect {
  width: 170px;
}

/* .p-dropdown-panel {
  width: 230px !important;
} */

.date_filter {
  width: 250px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: #ffffff;
   padding-left: 12px;
}

.date_filter input {
  font-weight: 500;
  height: 40px;
  font-size: 15px;
  outline: none;
  border: none;
  padding-left: 0 !important;
}

.date_filter input:focus-visible,
.date_filter input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.calender_icon {
  cursor: pointer;
}
