.bucketTag {
  color: rgba(134, 57, 206, 1);
  margin: 0;
}

.valueCardCallview {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 1rem 0.5rem;
  box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
  margin-bottom: 0.5rem;
}

.valueCardCallview p {
  color: rgba(52, 58, 64, 1);
}

.valueCardCallview div img {
  width: 1.4rem;
  margin-top: -0.3rem;
}

.valueCardCallview p {
  color: rgba(52, 58, 64, 1);
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 15px;
  font-weight: 500;
}

.green-point {
  width: 7px;
  height: 7px;
  background-color: green;
  border-radius: 50%;
}

.red-point {
  width: 7px;
  height: 7px;
  background-color: rgb(128, 0, 0);
  border-radius: 50%;
}

.neutral-point {
  width: 7px;
  height: 7px;
  background-color: rgb(255, 205, 4);
  border-radius: 50%;
}

.valueCardpadCallView {
  padding: 0 9px !important;
}

.valueCardCallView {
  background: rgba(134, 57, 206, 1);
  border-radius: 8px;
  padding: 1rem 0.5rem;
  box-shadow: 0px 2px 12px 0px rgba(37, 51, 66, 0.15);
  margin-bottom: 0.5rem;
}

.valueCardCallView h4,
.valueCardCallView p {
  color: rgba(52, 58, 64, 1);
}

.valueCardCallView div img {
  width: 1.4rem;
  margin-top: -0.3rem;
}

.valueCardCallView p {
  color: rgba(52, 58, 64, 1);
  margin: 0;
  margin-bottom: 0.3rem;
  font-size: 15px;
  font-weight: 500;
}

.col-md-8 h5 {
  color: #ffffff;
}

.form-group {
  border-radius: 10px !important;
}

/* manually Rechedule */

.flex-container {
  display: flex;
}

.Buckettext-div {
  padding: 10px;
  background-color: #ffffff;
  height: 100% !important;
}

.button-div {
  flex: 1;
  padding: 10px;
}

.keywordButton-div {
  height: 56px;
}

.keywordButton {
  background-color: #ffffff;
  border-color: none;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  border: none;
  padding: 1rem 2rem;
}

.flex-container {
  display: flex;
}

.color-div {
  flex: 1;
  padding: 10px;
}

.bucketButtonText .yellow {
  width: 50%;
  height: 100%;
  background-color: #E2E2E2;
  transition: background-color 0.3s;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bucketButtonText .red {
  width: 50%;
  height: 100%;
  background-color: #E2E2E2;
  margin: 5px;
  transition: background-color 0.3s;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bucketButtonText .green {
  width: 50%;
  height: 100%;
  background-color: #E2E2E2;
  margin: 5px;
  transition: background-color 0.3s;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bucketButton div:hover {
  cursor: pointer;
}

.bucketButton .red:hover {
  background: rgba(242, 156, 246, 1);
}

.bucketButton .green:hover {
  background: rgba(242, 156, 246, 1);
}

.bucketButton .yellow:hover {
  background: rgba(242, 156, 246, 1);
}

.bucketButtonText p {
  margin: 0;
}

.bucketButtonText {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content1 {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close-button1 {
  position: absolute;
  top: 10px;
  right: 10px;
}

button1 {
  cursor: pointer;
}

.popHead {
  color: rgba(126, 50, 203, 1);
}

.custom-modal1 {
  max-width: 70%;
  /* Adjust the width as needed */
  width: auto;
  height: 100%;
  /* Adjust the height as needed (e.g., 60% of the viewport height) */
}

.VLine img {
  height: 2rem;
}

.custumerId p {
  padding-right: 10px;
  padding-left: 15px;
}

.VLine img {
  padding-right: 10px;
}

.bucketCallDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ttlCallDiv {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.ttlCallDiv p {
  margin: 0 !important;
  font-size: 13px !important;
}

.manualCAll {
  padding: 1.5rem !important;
}

.manualCAll .input-div {
  padding: 0 !important;
}

.manualCAll .input-div input {
  height: 56px !important;
}
.callSearchDetail{
  /* height: 250px !important; */
  margin-right: 20px;
  margin-top: 41px; 
}

.selcteddaystocall {
  background-color: #F29CF6 !important;
  font-weight: 500;
}
.daystocall {
  cursor: pointer;
}

.lineChartCOlCall {
  background-color: #ffffff;
  border-radius: 13px;
  padding: 1rem;
  margin-top: 0 !important;
}

@media screen and (max-width: 1200px) {
  .callSearchDetail {
    margin-right: 0 !important;
  }

}