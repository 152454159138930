.centerNavBAr3 {
  background-color: #F7E8FC !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
}

.mainMenuItem2 {
  padding: 0 !important;
}

.hidden {
  display: none;
}
/* Style the select box */
select {
  appearance: revert !important;
  -webkit-appearance: revert !important;
  -moz-appearance: revert !important;
  
}
.width_100_per{
  width: 100%;
}

.margin_right_20{
  margin-right: 20px;
}
