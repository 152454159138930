@font-face {
  font-family: "Laconic";
  src: url("../assets/Fonts/Laconic_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Laconic";
  src: url("../assets/Fonts/Laconic_Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Laconic";
  src: url("../assets/Fonts/Laconic_Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Laconic Shadow";
  src: url("../assets/Fonts/Laconic_Shadow.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Red Five Regular";
  src: url("../assets/Fonts/redfive.regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.loading {
  font-size: 84px;
  font-weight: 500;
  text-align: center;
  font-family: "Red Five Regular";
  span {
    display: inline-block;
    margin: 0 -0.05em;
  }
}

.loader_container01 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    123.38deg,
    #f7e8fc 0%,
    #e1f6fe 24.1%,
    #f7e8fc 46.9%,
    #fffbd9 70.25%,
    #f7e8fc 90.61%
  );
  border-radius: 10px;
}

.loading {
  font-size: 2rem;
}

.loading01 span {
  animation: loading01 1.4s infinite alternate;
  font-size: 60px;
}

@media screen and (max-width: 768px) {
  .loading01 span {
    animation: loading01 1.4s infinite alternate;
    font-size: 50px;
  }
}

@media screen and (max-width: 576px) {
  .loading01 span {
    animation: loading01 1.4s infinite alternate;
    font-size: 30px;
  }

  .words-wrapper p {
    font-size: 14px;
  }
}

@media screen and (max-width: 380px) {
  .loading01 span {
    animation: loading01 1.4s infinite alternate;
    font-size: 24px;
  }

  .words-wrapper p {
    font-size: 14px;
  }
}

.loading01 span:nth-child(1) {
  animation-delay: 0s;
}

.loading01 span:nth-child(2) {
  animation-delay: 0.1s;
}

.loading01 span:nth-child(3) {
  animation-delay: 0.2s;
}

.loading01 span:nth-child(4) {
  animation-delay: 0.3s;
}

.loading01 span:nth-child(5) {
  animation-delay: 0.4s;
}

.loading01 span:nth-child(6) {
  animation-delay: 0.5s;
}

.loading01 span:nth-child(7) {
  animation-delay: 0.6s;
}

.loading01 span:nth-child(8) {
  animation-delay: 0.7s;
}

.loading01 span:nth-child(9) {
  animation-delay: 0.8s;
}

.loading01 span:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes loading01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.text34 {
  position: relative;
  top: 26%;
  left: 0;
  color: #000;
  width: 400px;
  margin: auto;
}

.text34 h4 {
  text-align: center;
}

.words-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
}

.words-wrapper p {
  opacity: 0;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  font-weight: 200;
  font-weight: 400;
  font-family: "Laconic";
}

.words-wrapper .is-visible {
  position: relative;
  opacity: 1;
  -webkit-animation: push-in 0.8s;
  -moz-animation: push-in 0.8s;
  animation: push-in 0.8s;
}

.words-wrapper .is-hidden {
  -webkit-animation: push-out 0.8s;
  -moz-animation: push-out 0.8s;
  animation: push-out 0.8s;
}
@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  70% {
    opacity: 0.8;
    -webkit-transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes push-in {
  0% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  60% {
    opacity: 0.8;
    -moz-transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@keyframes push-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateXY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  60% {
    opacity: 0.8;
    -webkit-transform: translateY(10%);
    -moz-transform: translateY(10%);
    -ms-transform: translateY(10%);
    -o-transform: translateY(10%);
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateY(110%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  60% {
    opacity: 0;
    -moz-transform: translateY(110%);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
}

@keyframes push-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    opacity: 0;
    -webkit-transform: translateX(110%);
    -moz-transform: translateY(110%);
    -ms-transform: translateY(110%);
    -o-transform: translateY(110%);
    transform: translateY(110%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
}
