.sadfdgfdf {
  left: -28% !important;
}

.adminoverviewSearch {
  background-color: #ffffff;
  width: fit-content;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  gap: 0.67rem;
  border-radius: 7px;
}

.adminoverviewSearch input {
  border: none;
  outline: none !important;
}

.adminoverviewSearch img {
  width: 1.4rem;
}

.dateDropdownform {
  background-color: #ffffff;
  padding: 0.6rem;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 7px;
}

.dateDropdownform select {
  border: none;
  outline: none !important;
  background-color: #ffffff;
}

.adminoverviewHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  margin-left: 10px;
  margin-top: 7px;
}

.customersCardLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customersCardLeftText p {
  color: #6e6e91;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}

.customersCardLeftText {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.customersCardrighttext p {
  margin: 0;
  color: #42bd53;
  font-size: 14px;
}

.customersCard {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1rem;
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  gap: 0.5rem;
  border-radius: 15px;
}

.customersCardright img {
  width: 70px;
}

.mapCOntainer {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 15px;
  /* height: 50%; */
}

.mapCOntainerHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mapCOntainerHead h6 {
  margin: 0;
}

.lineChartCOl12 {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0.5rem;
  margin-top: 1rem !important;
}

.yearBtn {
  background-color: #ffffff;
  color: black;
}

.nafiaTAbleCOntainer {
  border-radius: 15px;
  margin: 1rem 0;
}

.nafiaTAbleCOntainer tr th {
  background-color: transparent !important;
  padding-bottom: 1rem !important;
  font-weight: 500;
}

.nafiaTAbleCOntainer .table > :not(caption) > * > * {
  border: none;
}

.tableData1 {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  height: 45px;
}

.tableData1 p {
  margin: 0;
  color: #797d8c;
  font-size: 14px;
}

.tableData1 img {
  width: 2.2rem;
  border-radius: 50%;
}

.nafiaTAbleCOntainer tbody tr td {
  background-color: transparent !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.nafiaTAbleCOntainer tbody tr td div {
  background-color: #ffffff;
  padding: 0.6rem;
  height: 55px;
  display: flex;
  align-items: center;
}

.tableData2 p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}

.tableData3 p {
  margin: 0;
  font-size: 13px;
  color: #f7941d;
}

.tableData5 p {
  margin: 0;
  font-size: 13px;
  color: #43be83;
}

.tableData2 {
  height: inherit;
}

.tableData4 {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}

.tabledata1radius {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.radialChartHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.3rem;
}

.radialChartHead p {
  margin: 0;
  font-size: 13px;
}

.radialChartHead h6 {
  font-size: 15px;
}

.radialChartDiv {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.GoodRating {
  display: flex;
}

.radialChartBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goodCOlor {
  background-color: #7e32cb;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.AvarageCOlor {
  background-color: #f29cf6;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.badColor {
  background-color: #dd9196;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.GoodRating h6,
.GoodRating p {
  margin: 0;
}

.GoodRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.GoodRating div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.areaCHArtDiv {
  background-color: #ffffff;
  margin-top: 1rem;
  border-radius: 15px;
}

.chartHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.chartHeadingtext-right select {
  border: 1px solid #c5c5d3;
  border-radius: 10px;
  padding: 0.5rem;
  color: #8b8ba7 !important;
}

.amradataTable_container {
  background-color: #ffffff;
  border-radius: 15px !important;
  padding: 1rem;
}

.amradataTable {
  padding: 12px;
}

.amradataTable_container tbody tr td div {
  display: flex;
  align-items: center;
}

.amradataTable_container tbody tr td div p {
  margin: 0;
  font-size: 14px;
}

.amradataTable_container table.dataTable thead > tr > th.sorting {
  font-weight: 500;
  color: #a8a8bd;
  height: 55px;
}

table.dataTable.display > tbody > tr.odd > .sorting_1 {
  background-color: #ffffff !important;
}

.navbarRightNoti img {
  margin: 5px;
}

.navMail p {
  font-size: smaller;
}

/* ------------------------------------------------------------------------------------------------------------------ */

.searchContaienr {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1rem;
}

.searchContaienr h5 {
  margin-bottom: 1rem;
}

.searchInputsConta {
  margin-bottom: 1rem;
}

.searchInputsConta input {
  border: none;
  outline: none;
  height: 40px;
  background-color: #f2f0f0;
  border-radius: 6px;
  width: 100%;
  padding: 0 0.5rem;
  font-weight: 500;
}

.searchBtnADmin button {
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  width: 100%;
  justify-content: center;
  background-color: #f7e8fc;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.searchBtnADmin button p {
  margin: 0;
  font-size: 15px;
}

.searchClientContaienr {
  margin: 1rem 0;
}

.filterDrpdwn {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 6px;
}

.filterDrpdwn img {
  padding: 0 1rem;
}

.filterDrpdwnSelects {
  border: none !important;
  width: 100%;
  text-align: center;
  color: #343a40 !important;
  font-weight: 500;
}

.ticktfiltertableHead {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.ticktfiltertableHead h5 {
  margin: 0;
}

.ticktfiltertableContainer {
  background-color: #ffffff;
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 15px;
}

.table > :not(caption) > * > * {
  border-bottom: none !important;
}

.ticktstable {
  margin: 1rem 0 0.5rem 0;
}

.ticktstable .table thead tr th {
  color: #006aff;
  font-weight: 500 !important;
}

.ticktstable .table tbody tr td {
  color: #16161d;
}

.ticktfiltertableBtn button {
  color: #ffffff;
  border: none;
  border-radius: 30px;
  background-color: #006aff;
  padding: 0.4rem 1rem;
}

.ticktfiltertableBtn {
  display: flex;
  justify-content: flex-end;
}

/* ------------------------------------------------------------------------------------------------------------------------------- */

.loggedIngdevicesBtn p {
  background-color: #207dff;
  color: #ffffff;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.editUsrContrlBtn {
  background-color: #232323;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 0.2rem 1rem;
}

.remvUsrContrlBtn {
  background-color: transparent;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 2px 1rem;
}

.mngUserInput {
  margin-bottom: 1rem;
}

.mngUserInput .controlSlct,
.mngUserInput input {
  border: none;
  outline: none;
  background-color: #f2f0f0;
  height: 35px;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  font-size: 15px;
  width: 100%;
}

.mngUserInput .controlSlct {
  height: 40px !important;
}

.mngUserInput label {
  color: #525151;
}

.updateCnclebtnCan,
.updateCnclebtnUp {
  border: none;
  padding: 0.4rem 1.1rem;
  margin-right: 1rem;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.updateCnclebtnCan {
  background-color: #ec7cf1;
}

.updateCnclebtnCan:hover {
  background-color: #ed62f5;
}

.updateCnclebtnUp {
  background: #7e32cb;
}

.updateCnclebtnUp:hover {
  background: #741ace;
}

.AddAmraAdminuser {
  margin: 2rem;
  text-align: right;
}

.AddAmraAdminuser button {
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  border: none;
  padding: 0.4rem 1.5rem;
  color: #ffffff;
  font-weight: 500;
  border-radius: 7px;
  transition: all 1s ease;
}

.AddAmraAdminuser button:hover {
  background: linear-gradient(92.56deg, #f29cf6 27.99%, #7e32cb 109.64%);
}

.getNAdVerifyOPt p {
  margin: 0;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
}

.getred {
  color: red;
}

.getGreen {
  color: green;
}

.getred:hover,
.getGreen:hover {
  text-decoration: underline;
}

.getNAdVerifyOPt img {
  width: 2rem;
}

/* ------------------------------------------------------------------------------------------------------------------------------ */

.CIENTdETAILShEAD {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clientHeadleft p,
.clientHeadright p {
  margin: 0;
  font-weight: 500;
}

.clientHeadright {
  text-align: right;
}

.custom-modal1 {
  background-color: #f0f0f0;
  border-radius: 20px;
}

.custom-modal1 .modal-content {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.serverCostContainer {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  background-color: #ffffff;
  padding: 0.5rem;
  border-radius: 15px;
  height: 100%;
}

.serverCostContainer p,
.serverCostContainer h1 {
  margin: 0;
}

.callVSMonChart {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1rem;
}

.list-group {
  border-radius: 15px;
}

.list-group p {
  margin: 0;
  color: #6e6e91;
  font-weight: 500;
}

.clientticketsAll {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 15px;
  margin-top: 1rem;
}

.clientticketsAllHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clientticketsAllHead h5 {
  margin: 0;
  color: #7e32cb;
}

.clientticketsAllBtn {
  font-size: 14px;
  color: #7e32cb;
}

.clientticketsListItem {
  background-color: #f0f0f0;
  padding: 0.5rem;
  border-radius: 6px;
}

.clientticketsListItemHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clientticketsListItemHead p {
  font-size: 13px;
  margin: 0;
}

.clientticketsListItem {
  margin: 0.3rem 0;
}

.OtherDetailsBtns button {
  border: none;
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  color: #ffffff;
  width: 100%;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  padding: 0.5rem;
}

.clientDataCanelBtns {
  background: linear-gradient(
    92.56deg,
    #7e32cb 27.99%,
    #f29cf6 129.64%
  ) !important;
}

.clientEditBytns {
  background-color: #d9d9d9 !important;
  color: #000000;
}

.serverCostContainer img {
  padding: 0 1rem;
}

/* ------------------------------------------------------------Amra Agents Css----------------------------------------------------------- */

.AddAgentBtn {
  margin: 2rem;
  text-align: right;
}

.AddAgentBtn button {
  border: none;
  color: #ffffff;
  padding: 0.4rem 1.3rem;
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  border-radius: 6px;
  font-weight: 500;
}

.planTetx {
  background-color: #006aff;
  color: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
}

/* ------------------------------------------------------Access Control------------------------------------------------------ */

.searchImgPosition {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.2rem;
  width: 20px;
  height: 20px;
}

body {
  height: 100vh;
  background-color: #ececec;
}

.searchIcon {
  padding: 5px 8px;
  border: none;
  color: black;
  width: 100%;
  background-color: #f4f1f1;
  border-radius: 0.2rem;
}

.searchIcon::placeholder {
  color: #9d9ea1;
}

.searchInputBox {
  position: relative;
  width: 100%;
}

.totalRecordCard {
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.bankBox {
  cursor: pointer;
  list-style: none;
  font-weight: 500;
  color: #7e8086;
  width: 100%;
  margin: 1rem 0;
  background-color: rgb(255, 255, 255);
}

.card {
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: start;
  height: fit-content;
  min-height: 100%;
  color: #6e6e91;
  box-shadow: 0px 0px 4px 0px #00000040;
  border: none;
}

.card p:first-child {
  font-weight: 500;
  margin: 0;
  font-size: 13px;
}

.card h4 {
  font-weight: 600;
}

.circularBarandtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

/* -----------------------------------------------------------------Amra Customers-------------------------------------------------- */

.searchBarCustomer {
  background-color: #ffffff;
  height: 40px;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchBarCustomer input {
  border: none;
  outline: none;
  color: #5c6066;
  font-weight: 500;
}

.PickerOnboardRenewal {
  background-color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  position: relative;
}

.PickerOnboardRenewal input {
  width: 100%;
  border: none;
  color: #5c6066;
  font-weight: 500;
  outline: none;
}

.datePickerOnboardRenewal {
  display: flex;
  gap: 0.5rem;
}

.revewalDatePstn {
  position: absolute;
  top: -20px;
}

.SaveBtn2 {
  margin-top: 0px;
}

.row_top {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

/* --------------------------------------------------CRM notes Pop-Up------------------------------------------------------- */

.rightTextbg {
  background-color: #f29cf6;
}

.leftTextbg {
  background-color: #7e32cb;
}

.leftTextbg,
.rightTextbg {
  color: #ffffff;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  width: fit-content;
  max-width: 85%;
}

/* --------------------------------------------------------Nafia Admin Overview Page--------------------------------------------- */

.nafiaDataCard {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 15px;
}

.nafiaDataCArdsContainer {
  margin: 1rem 0;
}

.sevrerHeathData p {
  font-size: 13px;
}

.nafiaDataCard h6 {
  color: #6e6e91;
}

.nafiaDataCard h4 {
  font-weight: 600;
}

/* -------------------------------------------------NAfia User Management___________________________________________________________ */

.ttlClientNo {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0.3rem 1rem;
  height: 40px;
}

.ttlClientNoTxt {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ttlClientNo h5,
.ttlClientNoTxt p {
  margin: 0;
}

.ttlClientNoTxt img {
  width: 1.5rem;
}

.ttlClientNoHr {
  width: 2px;
  height: 23px !important;
  border-left: 1px solid #343a40;
  margin: 0 0.5rem !important;
}

.AddNafiaUsrBtn button {
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  border: none;
  height: 40px;
  color: #ffffff;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  font-size: 15px;
}

.btnClientNo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.L3Percent {
  position: absolute;
  top: 0px;
}

@media screen and (max-width: 1350px) {
  .nafiaTAbleCOntainer table.nafiaTAble {
    width: 1200px;
    overflow-x: scroll;
  }
  .nafiaTAbleCOntainer {
    width: 100%;
    overflow: scroll;
  }
}

/* --------------------------------------------------------Access Control Nafia------------------------------------------------- */

.tableData4 img {
  width: 1.2rem;
  margin-left: 1rem;
}

.nafiaADminDataTableHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ----------------------------------------------------------KB Management--------------------------------------------------------- */

.kbmngmtCardsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
}

.kbmngmtCardsDiv .kbmngmtCard,
.kbmngmtCardsDiv .card {
  width: 100%;
}

.kbmngmtCardsDiv .card {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.AddNafiaUsrBtn {
  text-align: right;
}

.kbAddFilemngmnt {
  width: 200px;
}

/* --------------------------------------------------------Call Center Performance--------------------------------------------- */

.gradientTextStyle {
  background: linear-gradient(92.56deg, #7e32cb 27.99%, #f29cf6 129.64%);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 500;
  font-size: 17px !important;
}

.postMvp {
  color: #58585a;
  font-weight: 500;
  font-size: 6px;
  border: 1px solid #6E6E91;
  border-radius: 5px;
  padding: 0.2rem 0.3rem;
  margin: 0;
}

.postMvpWhite {
  color: #ffffff !important;
  border-color: #ffffff !important;
}